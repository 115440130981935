// /**
//  * YEBOYEBO Pixel Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import PixelConfigsQuery from '../../query/PixelConfigs.query';

const addPixelConfigsToRequest = (args, callback) => ([
    ...callback(...args),
    PixelConfigsQuery.getQuery()
]);

const addPixelConfigsToState = (args, callback) => ({
    ...callback(...args),
    facebookPixelConfigs: {}
});

const getPixelConfigsFromAction = (args, callback) => {
    const [, action] = args;
    const {
        type,
        config: {
            facebookPixelConfigs = {}
        } = {}
    } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        facebookPixelConfigs
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addPixelConfigsToRequest
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addPixelConfigsToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getPixelConfigsFromAction
    }
};
