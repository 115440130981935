const addGenderField = (args, callback) => {
    return [
        ...callback(...args),
        'gender'
    ];
};

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields: addGenderField
        }
    }
};