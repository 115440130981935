import {
    checkForErrors as SourceCheckForErrors,
    debounce as SourceDebounce,
    Debouncer as SourceDebouncer,
    executeGet as SourceExecuteGet,
    executePost as SourceExecutePost,
    formatURI as SourceFormatURI,
    getFetch as SourceGetFetch,
    getGraphqlEndpoint as SourceGetGraphqlEndpoint,
    getStoreCodePath as SourceGetStoreCodePath,
    getWindowId as SourceGetWindowId,
    GRAPHQL_URI as SOURCE_GRAPHQL_URI,
    handleConnectionError as SourceHandleConnectionError,
    HTTP_201_CREATED as SOURCE_HTTP_201_CREATED,
    HTTP_410_GONE as SOURCE_HTTP_410_GONE,
    HTTP_503_SERVICE_UNAVAILABLE as SOURCE_HTTP_503_SERVICE_UNAVAILABLE,
    listenForBroadCast as SourceListenForBroadCast,
    postFetch as SourcePostFetch,
    putPersistedQuery as SourcePutPersistedQuery,
    WINDOW_ID as SOURCE_WINDOW_ID
} from 'SourceUtil/Request/Request';
import { getAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export const GRAPHQL_URI = SOURCE_GRAPHQL_URI;
export const WINDOW_ID = SOURCE_WINDOW_ID;

export const getWindowId = SourceGetWindowId;

export const getStoreCodePath = SourceGetStoreCodePath;

export const getGraphqlEndpoint = SourceGetGraphqlEndpoint;

/** @namespace Elganso/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();
    const cache = BrowserDatabase.getItem('X-Magento-Cache-Id');
    if ((cache !== undefined || cache !== null) && cache !== '') {
        return {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
            'X-Magento-Cache-Id': cache
        };
    }

    return {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
    };
};

export const formatURI = SourceFormatURI;

export const getFetch = SourceGetFetch;

export const putPersistedQuery = SourcePutPersistedQuery;

export const postFetch = SourcePostFetch;

export const checkForErrors = SourceCheckForErrors;

export const handleConnectionError = SourceHandleConnectionError;

/** @namespace Elganso/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace Elganso/Util/Request/parseResponse/Promise/promise/then */
        (res) => res.json().then(
            /** @namespace Elganso/Util/Request/parseResponse/Promise/promise/then/json/then/resolve */
            (res) => resolve(checkForErrors(res)),
            /** @namespace Elganso/Util/Request/parseResponse/Promise/promise/then/json/then/reject/catch */
            () => reject()
        ),
        /** @namespace Elganso/Util/Request/parseResponse/Promise/promise/then/catch */
        (err) => {
            handleConnectionError('Can not establish connection!');

            return reject(err);
        }
    );
});

export const HTTP_503_SERVICE_UNAVAILABLE = SOURCE_HTTP_503_SERVICE_UNAVAILABLE;

export const HTTP_410_GONE = SOURCE_HTTP_410_GONE;

export const HTTP_201_CREATED = SOURCE_HTTP_201_CREATED;

export const executeGet = SourceExecuteGet;

export const executePost = SourceExecutePost;

export const listenForBroadCast = SourceListenForBroadCast;

export const debounce = SourceDebounce;

/** @namespace Elganso/Util/Request */
export class Debouncer extends SourceDebouncer {
    // TODO extend Debouncer
}
