/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import { SECOND_SECTION } from 'Type/Account.type';

import {
    BILLING_AGREEMENT,
    BILLING_AGREEMENTS
} from '../../route/PayPalBillingAgreementViewPage/PayPalBillingAgreementViewPage.config';
import { addNewTabToTabsMap } from '../../util/MyAccount';

export const PayPalBillingAgreementsPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-address" */
    '../../route/PayPalBillingAgreementsPage'
));

export const PayPalBillingAgreementViewPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-address" */
    '../../route/PayPalBillingAgreementViewPage'
));

const addPayPalBillingAgreementsToTabMap = (originalMembers) => {
    const newTab = {
        [BILLING_AGREEMENTS]: {
            url: '/paypal/billing_agreement',
            tabName: __('Billing Agreements'),
            section: SECOND_SECTION,
            isFullUrl: true
        }
    };

    return addNewTabToTabsMap(originalMembers, newTab);
};

const addPayPalBillingAgreementsToRenderMap = (originalMembers) => ({
    ...originalMembers,
    [BILLING_AGREEMENTS]: PayPalBillingAgreementsPage,
    [BILLING_AGREEMENT]: PayPalBillingAgreementViewPage
});

const addPayPalBillingAgreementsToTabContent = (args, callback, instance) => {
    const { activeTab, location: { pathname } } = instance.props;

    if (activeTab === BILLING_AGREEMENTS && pathname.includes('/paypal/billing_agreement/view/agreement/')) {
        return instance.renderMap[BILLING_AGREEMENT];
    }

    return callback(...args);
};

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: addPayPalBillingAgreementsToTabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addPayPalBillingAgreementsToRenderMap
        },
        'member-function': {
            getTabContent: addPayPalBillingAgreementsToTabContent
        }
    }
};
