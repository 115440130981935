// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import recomendadosCarritoConfigQuery from '../../query/recomendadosCarritoConfig.query';

const addrecomendadosCarritoConfigToRequest = (args, callback) => ([
    ...callback(...args),
    recomendadosCarritoConfigQuery.getQuery()
]);

const addrecomendadosCarritoConfigToState = (args, callback) => ({
    ...callback(...args),
    recomendadosCarritoConfig: {}
});

const getrecomendadosCarritoConfigFromAction = (args, callback) => {
    const [, action] = args;
    const {
        type,
        config: {
            recomendadosCarritoConfig = {}
        } = {}
    } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        recomendadosCarritoConfig
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addrecomendadosCarritoConfigToRequest
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addrecomendadosCarritoConfigToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getrecomendadosCarritoConfigFromAction
    }
};
