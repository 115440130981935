/**
 * Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace YeboyeboHreflang/Query/PopupBuilderConfig/Query */
export class PopupBuilderConfigQuery {
    getQuery() {
        return new Field('getPopUpListConditions')
            .addFieldList(this.getpopupBuilderConfigFields());
    }

    getpopupBuilderConfigFields() {
        return [
            'name',
            'id',
            'display',
            'trigger'
        ];
    }
}

export default new PopupBuilderConfigQuery();
