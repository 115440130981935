/**
 * YEBOYEBO Hreflang compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */

const YeboyeboAddHrefLang = (args, callback, instance) => {
    const { urlRewrite: prevUrlRewrite } = args[0];
    const { urlRewrite } = instance.props;
    if (urlRewrite?.additional_data && !prevUrlRewrite?.additional_data) {
        // const isInHead = document.head.querySelectorAll('[hreflang="' + index + '"]');
        // const hasCanonical = document.querySelectorAll("link[rel='canonical']");
        // eslint-disable-next-line max-len
        if (instance.props.urlRewrite.additional_data?.hrefLang && instance.props.urlRewrite.additional_data?.hrefLang !== 'null') {
            const hrefLangItems = JSON.parse(instance.props.urlRewrite.additional_data.hrefLang);
            let cont = 0;

            // eslint-disable-next-line array-callback-return
            Object.entries(hrefLangItems).map(([index, item]) => {
                // eslint-disable-next-line prefer-const
                let link = document.createElement('link');
                link.rel = 'alternate';
                link.hreflang = index;
                link.href = item;
                document.head.appendChild(link);
                // if (item.includes(instance.props.location.pathname) && index !== 'x-default') {
                if (index === 'x-default') {
                    link.href = item.replace(`${instance.props.location.pathname.split('/')[1] }/`, '');
                }
                if (cont === 0 && index !== 'x-default') {
                    // eslint-disable-next-line prefer-const
                    let canonical = document.createElement('link');
                    canonical.rel = 'canonical';
                    canonical.href = item;
                    document.head.appendChild(canonical);
                }
                cont++;
            });
        }
    } else if (urlRewrite?.notFound) {
        if (window.localStorage.config) {
            const localStorageConfig = JSON.parse(window.localStorage.config);
            const hrefLangBase = JSON.parse(localStorageConfig.data.hrefLangConfig.hrefLangBase);
            const hasCanonical = document.querySelectorAll("link[rel='canonical']");
            if (hasCanonical) {
                const pathUrl = 'no-route';
                // eslint-disable-next-line array-callback-return
                Object.entries(hrefLangBase).map(([index, item]) => {
                    // eslint-disable-next-line prefer-const
                    let link = document.head.querySelectorAll(`[hreflang="${ index }"]`)[0];
                    if (link) {
                        link.href = item + pathUrl;
                        if (index === 'x-default') {
                            link.href = item;
                        }
                        if (index === 'x-default') {
                            // eslint-disable-next-line prefer-const
                            let canonical = document.head.querySelectorAll('[rel="canonical"]')[0];
                            if (canonical) {
                                canonical.href = item + pathUrl;
                            }
                        }
                    }
                });
            }
        }
    }

    return callback(...args);
};

export default {
    'Route/UrlRewrites/Container': {
        'member-function': {
            componentDidUpdate: YeboyeboAddHrefLang
        }
    }
};
