/* eslint-disable react/forbid-elements */
import {
    GoogleApiWrapper as googleApiWrapper, InfoWindow, Map, Marker
} from 'google-maps-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './YBGoogleMaps.style';

/** @namespace Elganso/Component/YBGoogleMaps/Component */
export class YBGoogleMaps extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        localizar: PropTypes.func.isRequired
    };

    renderMarkerItem(item) {
        const {
            activePoint, markerIcon, onMarkerClick, onMarkerMounted
        } = this.props;

        return (
            <Marker
              key={ item.codAlmacen }
              onClick={ onMarkerClick }
              ref={ activePoint !== null && activePoint === item.codAlmacen ? onMarkerMounted : null }
              name={ item.codAlmacen }
              position={ { lat: item.latitud, lng: item.longitud } }
              icon={ {
                  url: markerIcon,
                  // eslint-disable-next-line no-undef
                  scaledSize: new google.maps.Size(36, 36)
              } }
            />
        );
    }

    renderMarker() {
        const { points } = this.props;

        return points.map(this.renderMarkerItem.bind(this));
    }

    renderInfo() {
        const { points, selectedPlace } = this.props;
        let datosPoint = {};
        let tallas = '';

        if (points !== undefined && points.length > 0) {
            datosPoint = points.filter((item) => {
                if (item.codAlmacen === selectedPlace?.name) {
                    tallas = `<strong>${ __('Available sizes') }: `;

                    item.tallas.forEach((talla, id) => {
                        if (item.tallas.length === 1 || id === item.tallas.length - 1) {
                            tallas += `${ talla }`;
                        } else {
                            tallas += `${ talla }, `;
                        }
                    });

                    tallas += '</strong>';
                }

                return item.codAlmacen === selectedPlace?.name;
            })[0] || {};
        }

        return (
            <div>
                <p className="textInfoMarginBottom2">
                    <strong>{ datosPoint.nombre }</strong>
                </p>
                <p className="textInfo textInfoMarginBottom1">
                    { datosPoint.direccion }
                </p>
                <p className="textInfo textInfoMarginBottom1">
                    { `${ datosPoint.ciudad } (${ datosPoint.provincia })` }
                </p>
                <p className="textInfo textInfoMarginBottom1">
                    { datosPoint.codpostal }
                </p>
                <p className="textInfo textInfoMarginBottom1">
                    <a
                      className="textInfo"
                      href={ `tel:${ datosPoint.telefono }` }
                    >
                        { datosPoint.telefono }
                    </a>
                </p>
                <p className="textInfo textInfoMarginBottom1">
                    { tallas !== null
                        ? <Html content={ tallas } />
                        : null }
                </p>
            </div>
        );
    }

    renderMap() {
        const {
            activeMarker,
            google,
            mapCenterLat,
            mapCenterLng,
            mapLoaded,
            mapZoom,
            onMapClicked,
            onMapIdle,
            showingInfoWindow,
            type
        } = this.props;
        const styles = {};

        return (
            <Map
              key={ type }
              google={ google }
              style={ styles }
              onClick={ onMapClicked }
              onIdle={ onMapIdle }
              zoom={ mapZoom }
              initialCenter={ {
                  lat: mapCenterLat,
                  lng: mapCenterLng
              } }
              center={ {
                  lat: mapCenterLat,
                  lng: mapCenterLng
              } }
            >
                { mapLoaded && (
                    this.renderMarker()
                ) }
                <InfoWindow
                  key="infowindow"
                  marker={ activeMarker }
                  visible={ showingInfoWindow }
                >
                    { this.renderInfo() }
                </InfoWindow>
            </Map>
        );
    }

    render() {
        // Lo utilizamos para inicializar el mapa, NO QUITAR
        const { localizar } = this.props;
        localizar();

        return (
            <div block="YBGoogleMaps">
                { this.renderMap() }
            </div>
        );
    }
}

export default googleApiWrapper({
    apiKey: 'AIzaSyAY-ltaDokKSQLcNBLVpcOMlCUNtbmiWGo'
})(YBGoogleMaps);
