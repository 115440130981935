import {
    DELIVERY_METHOD_UNAVAILABLE_MESSAGE as SOURCE_DELIVERY_METHOD_UNAVAILABLE_MESSAGE,
    DISPLAY_SHIPPING_PRICES_BOTH as SOURCE_DISPLAY_SHIPPING_PRICES_BOTH,
    DISPLAY_SHIPPING_PRICES_EXCL_TAX as SOURCE_DISPLAY_SHIPPING_PRICES_EXCL_TAX,
    DISPLAY_SHIPPING_PRICES_INCL_TAX as SOURCE_DISPLAY_SHIPPING_PRICES_INCL_TAX
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.config';

// TODO: implement DISPLAY_SHIPPING_PRICES_INCL_TAX
export const DISPLAY_SHIPPING_PRICES_INCL_TAX = SOURCE_DISPLAY_SHIPPING_PRICES_INCL_TAX;

// TODO: implement DISPLAY_SHIPPING_PRICES_EXCL_TAX
export const DISPLAY_SHIPPING_PRICES_EXCL_TAX = SOURCE_DISPLAY_SHIPPING_PRICES_EXCL_TAX;

// TODO: implement DISPLAY_SHIPPING_PRICES_BOTH
export const DISPLAY_SHIPPING_PRICES_BOTH = SOURCE_DISPLAY_SHIPPING_PRICES_BOTH;

// TODO: implement DELIVERY_METHOD_UNAVAILABLE_MESSAGE
export const DELIVERY_METHOD_UNAVAILABLE_MESSAGE = SOURCE_DELIVERY_METHOD_UNAVAILABLE_MESSAGE;
