/* eslint-disable no-magic-numbers */
/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
import ReactPixel from 'react-facebook-pixel';

function stringToHash(string) {
    let hash = 0;

    if (string.length == 0) {
        return hash;
    }

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return String(hash);
}

function generateEventId(eName) {
    const uCookie = document.cookie;
    const uHash = stringToHash(uCookie);
    const url = window.location.href;
    const urlHash = stringToHash(url);

    function getTimeStamp() {
        if (!Date.now) {
            Date.now = () => {
                new Date().getTime();
            };
        }

        return Date.now();
    }

    const timestamp = String(getTimeStamp());

    return eName + uHash + urlHash + timestamp;
}

const facebookPixelNewsletterSubscriptionPlugin = (args, callback) => {
    const { fbq } = window;

    if (fbq && window.localStorage.config !== undefined) {
        const pixelArgs = { };

        if (args[1]) {
            const emailField = args[1].find((element) => element.type == 'email');

            if (Object.values(emailField).indexOf('newsletterEmail') > -1) {
                pixelArgs.emailSuscripcion = emailField.value;
            }
        }

        ReactPixel.fbq('trackCustom', 'SuscripcionNewsletter', pixelArgs, { eventID: generateEventId('SuscripcionNewsletter') });
    }

    return callback(...args);
};

export default {
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit: facebookPixelNewsletterSubscriptionPlugin
        }
    }
};
