import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import ItemListTiendaDisponibilidad from 'Component/ItemListTiendaDisponibilidad';
import Loader from 'Component/Loader';
import TextPlaceholder from 'Component/TextPlaceholder';
import YBGoogleMaps from 'Component/YBGoogleMaps';

import './DisponibilidadEnTienda.style';

/** @namespace Elganso/Component/DisponibilidadEnTienda/Component */
export class DisponibilidadEnTienda extends PureComponent {
    handleTabClicked = this.handleTabClicked.bind(this);

    handleTabClicked(e, tab) {
        const { onClickTab } = this.props;

        e.preventDefault();
        onClickTab(tab);
    }

    setRefFunction() {
        return Math.random();
    }

    renderButtonIcon() {
        return (
            <FontAwesomeIcon icon={ faLocationDot } />
        );
    }

    renderRespuestaConsultaDisponibilidad() {
        const {
            currentTab, errorDisponibilidad, mensajeDisponibilidad, onClickVisitMapStore, product, respuestaDisponibilidad, visitMapStore
        } = this.props;

        if (mensajeDisponibilidad !== '') {
            if (errorDisponibilidad) {
                return (
                    <ul className="localizaciones">
                        <li key="li-aviso"><p className="aviso">{ mensajeDisponibilidad }</p></li>
                    </ul>
                );
            }

            const renderTallas = respuestaDisponibilidad.map((tienda) => (
                <li key={ `li-${ tienda.codAlmacen }` } className="tienda">
                    <ItemListTiendaDisponibilidad id={ `itemList-${ tienda.codAlmacen }` } tienda={ tienda } product={ product } onClickVisitMapStore={ onClickVisitMapStore } />
                </li>
            ));

            let tiendas = respuestaDisponibilidad;

            if (visitMapStore !== null) {
                // eslint-disable-next-line array-callback-return, consistent-return
                tiendas = respuestaDisponibilidad.filter((item) => {
                    if (item.codAlmacen === visitMapStore) {
                        return item;
                    }
                });
            }

            return (
                <>
                    <div
                      block="DisponibilidadEnTienda"
                      elem="CartTabItem"
                      className={ currentTab === 'LIST' ? 'opened' : 'closed' }
                    >
                        <ul className="localizaciones">
                            <li key="li-aviso"><p className="aviso">{ mensajeDisponibilidad }</p></li>
                            { renderTallas }
                        </ul>
                    </div>
                    <div
                      block="DisponibilidadEnTienda"
                      elem="CartTabItem"
                      className={ currentTab === 'MAP' ? 'opened' : 'closed' }
                    >
                        <ul className="localizaciones">
                            <li key="li-aviso"><p className="aviso">{ mensajeDisponibilidad }</p></li>
                        </ul>
                        <YBGoogleMaps activePoint={ visitMapStore } points={ tiendas } type="ybdisponibilidadtienda" />
                    </div>
                </>
            );
        }

        return null;
    }

    renderDisponibilidadEnTiendaForm() {
        const { product, onZipCodeChange, onSizeSelected } = this.props;
        const sizes = [];

        if (product.type_id === 'configurable') {
            let tallas = product.configurable_options.size?.attribute_options;
            if (tallas) {
                // Ordenamos las tallas para mostrarlas en el select
                tallas = Object.keys(tallas).sort().reduce(
                    (obj, key) => {
                        const objeto = obj;
                        objeto[tallas[key].label] = tallas[key];
                        return objeto;
                    },
                    {}
                );

                Object.keys(tallas).forEach((talla) => {
                    sizes.push({
                        key: talla, id: talla, value: talla, label: talla
                    });
                });
            }
        } else {
            sizes.push({
                key: 'Talla Única', id: 'Talla Única', value: '0', label: __('One Size')
            });
        }

        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="Fields">
                    <Field
                      key="cp-ciudad"
                      type={ FIELD_TYPE.text }
                      attr={ {
                          id: 'cp-ciudad',
                          name: 'cp-ciudad',
                          defaultValue: '',
                          placeholder: `${__('Zip/Postal Code or City') } *`
                      } }
                      events={ { onChange: onZipCodeChange } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true
                      } }
                      setRef={ this.setRefFunction }
                    />
                    <Field
                      key="talla"
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'talla',
                          name: 'talla',
                          selectPlaceholder: `${__('Size') }`
                      } }
                      options={ sizes }
                      events={ { onChange: onSizeSelected } }
                      validateOn={ ['onChange'] }
                      setRef={ this.setRefFunction }
                    />
                </div>
            </div>
        );
    }

    renderSubmitButton() {
        const { submitForm } = this.props;

        return (
            <div block="DisponibilidadEnTienda" elem="Buttons">
                <button
                  block="Button"
                  type="submit"
                  onClick={ submitForm }
                  mix={ { block: 'DisponibilidadEnTienda', elem: 'SubmitButton' } }
                >
                    { __('Locate garment') }
                </button>
            </div>
        );
    }

    renderPopupContent() {
        const { currentTab, isLoading } = this.props;

        return (
            <div
              block="DisponibilidadEnTienda"
              elem="PopupContainer"
            >
                <div
                  block="DisponibilidadEnTienda"
                  elem="PopupHeading"
                >
                    <TextPlaceholder content={ __('AVAILABILITY IN STORES') } length="medium" />
                </div>
                <div
                  block="DisponibilidadEnTienda"
                  elem="PopupContent"
                >
                    <div
                      block="DisponibilidadEnTienda"
                      elem="PopupDescription"
                    >
                        <TextPlaceholder content={ __('To check if an article is available, enter your postcode or city and select your preferred store (The availability shown is for informational purposes only.)') } length="paragraph" />
                    </div>
                    <div
                      block="DisponibilidadEnTienda"
                      elem="PopupForm"
                    >
                        { this.renderDisponibilidadEnTiendaForm() }
                        { this.renderSubmitButton() }
                    </div>
                </div>
                <div
                  block="DisponibilidadEnTienda"
                  elem="PopupFooter"
                >
                    <div block="DisponibilidadEnTienda" elem="CartTabs">
                        <span
                          className={ currentTab === 'LIST' ? 'tab activeTab' : 'tab' }
                          onClick={ (e) => this.handleTabClicked(e, 'LIST') }
                          onKeyDown={ (e) => this.handleTabClicked(e, 'LIST') }
                        >
                            { __('List') }
                        </span>
                        <span
                          className={ currentTab === 'MAP' ? 'tab activeTab' : 'tab' }
                          onClick={ (e) => this.handleTabClicked(e, 'MAP') }
                          onKeyDown={ (e) => this.handleTabClicked(e, 'MAP') }
                        >
                            { __('Map') }
                        </span>
                    </div>

                    <div block="DisponibilidadEnTienda" elem="CartTabsContent">
                        <Loader isLoading={ isLoading } />
                        { this.renderRespuestaConsultaDisponibilidad() }
                    </div>
                </div>
            </div>
        );
    }

    renderDisponibilidadEnTienda() {
        const { clickShowDisponibilidadTiendas, showDisponibilidadTiendas } = this.props;

        return (
            <>
                <div
                  role="button"
                  tabIndex={ 0 }
                  block="DisponibilidadEnTienda-Popup ExpandableContent"
                  onClick={ clickShowDisponibilidadTiendas }
                  onKeyDown={ clickShowDisponibilidadTiendas }
                >
                    <div
                      block="DisponibilidadEnTienda-Popup ExpandableContent"
                      elem="Heading"
                    >
                        { this.renderButtonIcon() }
                        <TextPlaceholder content={ __('AVAILABILITY IN STORES') } length="medium" />
                    </div>
                </div>
                <div
                  block="DisponibilidadEnTienda"
                  elem="ContentWindow"
                  className={ showDisponibilidadTiendas ? '' : 'hideDisponibilidad' }
                >
                    <FontAwesomeIcon onClick={ clickShowDisponibilidadTiendas } className="closeDisponibilidad faXmark" icon={ faXmark } />
                    { this.renderPopupContent() }
                </div>
            </>
        );
    }

    render() {
        return (
            <ContentWrapper
              label="Disponibilidad En Tienda"
              mix={ { block: 'DisponibilidadEnTienda' } }
              wrapperMix={ { block: 'DisponibilidadEnTienda', elem: 'Wrapper' } }
            >
                { this.renderDisponibilidadEnTienda() }
            </ContentWrapper>
        );
    }
}

export default DisponibilidadEnTienda;
