import {
    MyAccountQuery as SourceOrderQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Elganso/Query/MyAccount/Query */
export class MyAccountQuery extends SourceOrderQuery {
    getSignInMutation(options) {
        const { email, password, sorteo } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('sorteo', 'String', sorteo)
            .addField('token')
            .addField('messageSorteo');
    }

    getAutoLoginToken(email, password) {
        return new Field('getAutoLoginToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password);
    }

    autoLogin(token) {
        return new Field('autoLogin')
            .addArgument('token', 'String!', token);
    }

    createCustomerGansociety(id) {
        return new Field('createCustomerGansociety')
            .addArgument('id', 'Int!', id);
    }

    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            'subscriber_categories'
        ];
    }

    getUpdateSubscriptionCategoriesMutation(options) {
        const {
            subscriber_categories, customer_id, customer_email, store_id
        } = options;

        return new Field('updateCustomerSubscriptionCategories')
            .addArgument('categories', 'String', subscriber_categories)
            .addArgument('customer_id', 'Int', customer_id)
            .addArgument('customer_email', 'String', customer_email)
            .addArgument('store_id', 'Int', store_id);
    }
}

export default new MyAccountQuery();
