/* eslint-disable no-magic-numbers */
/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
import ReactPixel from 'react-facebook-pixel';

function stringToHash(string) {
    let hash = 0;

    if (string.length == 0) {
        return hash;
    }

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return String(hash);
}

function generateEventId(eName) {
    const uCookie = document.cookie;
    const uHash = stringToHash(uCookie);
    const url = window.location.href;
    const urlHash = stringToHash(url);

    function getTimeStamp() {
        if (!Date.now) {
            Date.now = () => {
                new Date().getTime();
            };
        }

        return Date.now();
    }

    const timestamp = String(getTimeStamp());

    return eName + uHash + urlHash + timestamp;
}

const facebookPixelCategoryEvent = (args, callback, instance) => {
    const { fbq } = window;

    if (fbq) {
        const { category: prevCategory } = args[0];
        const { category } = instance.props;
        if (category !== prevCategory) {
            ReactPixel.fbq('trackCustom', 'ViewCategory', { name: category.name }, { eventID: generateEventId('ViewCategory') });
        }
    }

    return callback(...args);
};

const facebookPixelSearchEvent = (args, callback, instance) => {
    const { fbq } = window;

    if (fbq) {
        const { isSearchPage, match } = instance.props;
        if (isSearchPage) {
            ReactPixel.fbq('trackCustom', 'Search', { search_string: match?.params.query }, { eventID: generateEventId('Search') });
        }
    }

    return callback(...args);
};

export default {
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidUpdate: facebookPixelCategoryEvent,
            componentDidMount: facebookPixelSearchEvent
        }
    }
};
