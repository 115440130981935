/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import TagManager from 'react-gtm-module';

const gogleTagManagerCheckoutPurchase = (args, callback, instance) => {
    const localStorageConfig = JSON.parse(window.localStorage.config);
    const gtmID = localStorageConfig.googleConfigs?.analitysID;
    const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;

    if (gtm) {
        const { previousCartTotals, orderID } = instance.props;
        if (previousCartTotals.items) {
            // eslint-disable-next-line prefer-destructuring
            const prices = previousCartTotals.prices;
            const shipping = previousCartTotals.shipping_addresses.selected_shipping_method;
            const category = '';
            // eslint-disable-next-line arrow-body-style
            const products = previousCartTotals.items.map((item, index) => {
                return {
                    id: item.product.sku,
                    name: item.product.name,
                    price: item.prices.price.value,
                    // eslint-disable-next-line object-shorthand
                    category: category,
                    qty: item.quantity,
                    position: index
                };
            });

            const tagManagerArgs = {
                gtmId: gtmID,
                dataLayer: {
                    event: 'purchase',
                    ecommerce: {
                        purchase: {
                            // eslint-disable-next-line object-shorthand
                            products: products,
                            actionField: {
                                id: orderID,
                                affiliation: 'El Ganso',
                                revenue: prices.grand_total.value,
                                tax: shipping.tax_amount,
                                shipping: shipping.amount.value,
                                email: previousCartTotals.email
                            }
                        }
                    }
                },
                dataLayerName: 'YeboyeboDataLayer'
            };

            TagManager.dataLayer(tagManagerArgs);
        }
    }

    return callback(...args);
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            renderResumenPedido: gogleTagManagerCheckoutPurchase
        }
    }
};
