Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/yeboyebo-hreflang/src/plugin/Config/Config.plugin.js'),
require('./../packages/yeboyebo-hreflang/src/plugin/ConfigPopupBuilder/ConfigPopupBuilder.plugin.js'),
require('./../packages/yeboyebo-hreflang/src/plugin/ConfigRecomendadosCarrito/ConfigRecomendadosCarrito.plugin.js'),
require('./../packages/yeboyebo-hreflang/src/plugin/Router/Router.plugin.js'),
require('./../packages/yeboyebo-hreflang/src/plugin/UrlRewrites/UrlRewrites.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/AddToCart/AddToCart.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/AddToCart/Product.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/CategoryPage/CategoryPage.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/Checkout/CheckoutSuccess.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/Config/Config.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/MyAccountQuery/MyAccountQuery.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/NewsletterSubscription/NewsletterSubscription.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/ProductWishlist/ProductWishlistButton.plugin.js'),
require('./../packages/yeboyebo-facebookbusiness/src/plugin/Router/Router.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/AddTocart/AddToCart.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/AddTocart/Product.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/CartItem/CartItem.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/CategoryPage/CategoryPage.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/Checkout/CartPage.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/Checkout/Checkout.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/Checkout/CheckoutSucess.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/Config/Config.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/CreateAccount/CreateAccount.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/ProductWishlist/ProductWishlistButton.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/Router/Router.plugin.js'),
require('./../packages/yeboyebo-googletagmanager/src/plugin/UrlRewrites/UrlRewrites.plugin.js'),
require('./../packages/paypal-express/src/plugin/BillingAgreements/BillingAgreementsTab.plugin.js'),
require('./../packages/paypal-express/src/plugin/Checkout/Billing.plugin.js'),
require('./../packages/paypal-express/src/plugin/Checkout/Payments.plugin.js'),
require('./../packages/paypal-express/src/plugin/Checkout/Success.plugin.js'),
require('./../packages/paypal-express/src/plugin/Config/Config.plugin.js'),
require('./../packages/paypal-express/src/plugin/Context/App.plugin.js'),
require('./../packages/paypal-express/src/plugin/Messages/CartPage.plugin.js'),
require('./../packages/paypal-express/src/plugin/Messages/CategoryPage.plugin.js'),
require('./../packages/paypal-express/src/plugin/Messages/HomePage.plugin.js'),
require('./../packages/paypal-express/src/plugin/Messages/ProductPage.plugin.js'),
require('./../packages/paypal-express/src/plugin/Router/Router.plugin.js'),
require('./../packages/paypal-express/src/plugin/SmartButtons/CartPage.plugin.js'),
require('./../packages/paypal-express/src/plugin/SmartButtons/MiniCart.plugin.js'),
require('./../packages/paypal-express/src/plugin/SmartButtons/ProductPage.plugin.js'),
require('./../packages/stripe/src/plugin/Account/Tabs.plugin.js'),
require('./../packages/stripe/src/plugin/Checkout/Error.plugin.js'),
require('./../packages/stripe/src/plugin/Checkout/Payments.plugin.js'),
require('./../packages/stripe/src/plugin/Checkout/Reconfirm.plugin.js'),
require('./../packages/stripe/src/plugin/Checkout/Success.plugin.js'),
require('./../packages/stripe/src/plugin/Config.plugin.js'),
require('./../packages/stripe/src/plugin/Express/CartOverlay.plugin.js'),
require('./../packages/stripe/src/plugin/Express/CartPage.plugin.js'),
require('./../packages/stripe/src/plugin/Express/Checkout.plugin.js'),
require('./../packages/stripe/src/plugin/Express/ProductActions.plugin.js'),
require('./../packages/stripe/src/plugin/Summary/CartItem.plugin.js'),
require('./../packages/stripe/src/plugin/Summary/CartQuery.plugin.js'),
require('./../packages/stripe/src/plugin/Summary/CheckoutOrderSummary.plugin.js'),
require('./../packages/yeboyebo-products/src/plugin/ProductPage.component.plugin.js'),
require('./../packages/yeboyebo-products/src/plugin/YBProductPage.component.plugin.js')]);
/* eslint-disable no-console */
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        const version = 'version=1';
        const scope = `/?${ version }`;

        navigator.serviceWorker.register(swUrl, { scope })
            .then(
                /** @namespace Elganso/Index/window/addEventListener/catch/then/catch/register/then */
                (_registration) => {
                    navigator.serviceWorker.getRegistrations()
                        .then(
                            /** @namespace Elganso/Index/window/addEventListener/catch/then/catch/register/then/getRegistrations/then */
                            (registrations) => {
                                registrations.forEach(
                                    (registration) => {
                                        if (registration.scope.includes('?version')) {
                                            const registrationVersion = registration.scope.split('?')[1];

                                            if (registrationVersion !== version) {
                                                registration.unregister();
                                            }
                                        } else {
                                            registration.unregister();
                                        }
                                    }
                                );
                            }
                        );
                }
            )
            .catch(
                /** @namespace Elganso/Index/window/addEventListener/catch/then/catch */
                (error) => {
                    console.log('ServiceWorker registration failed: ', error);
                }
            );

        // navigator.serviceWorker.getRegistrations()
        //     .then(
        //         /** @namespace Elganso/Index/window/addEventListener/catch/getRegistrations/then */
        //         (registrations) => {
        //             foreach (const registration of registrations) {
        //                 registration.unregister();
        //             }
        //         }
        //     );
    });
}

render(<App />, document.getElementById('root'));
