import {
    filterData as sourceFilterData,
    getInitialState as sourceGetInitialState,
    MetaReducer as SourceMetaReducer,
    updateEveryTime as sourceUpdateEveryTime
} from 'SourceStore/Meta/Meta.reducer';

// TODO: implement updateEveryTime
export const updateEveryTime = sourceUpdateEveryTime;

// TODO: implement filterData
export const filterData = sourceFilterData;

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

// TODO: implement MetaReducer
export const MetaReducer = SourceMetaReducer;

export default MetaReducer;
