import { createPortal } from 'react-dom';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Elganso/Component/Meta/Component */
export class Meta extends SourceMeta {
    componentDidMount() {
        // Remove prerendered meta tags so dynamic meta tags can take effect
        Array.prototype.slice.call(
            document.head.querySelectorAll('title[data-prerendered], meta[data-prerendered]')
        ).forEach((tag) => {
            document.head.removeChild(tag);
        });
    }

    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix }` : '';
        const titleSuffix = title_suffix ? ` ${ title_suffix }` : '';

        return (
            <title>
                { `${ titlePrefix }${ title || default_title }${ titleSuffix }` }
            </title>
        );
    }

    renderCanonical() {
        return null;
        // const { canonical_url } = this.props;

        // if (!canonical_url) {
        //     return null;
        // }

        // return (
        //     <link rel="canonical" href={ canonical_url } />
        // );
    }

    renderStructured() {
        const { title, description, keywords } = this.props;

        const data = {
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            name: title,
            description,
            keywords,
            url: location.href
        };

        return JSON.stringify(data);
    }

    renderOgMeta() {
        const { title, description } = this.props;
        return (
            <>
                <meta name="apple-itunes-app" content="app-id=1173967862" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={ title } />
                <meta property="og:description" content={ description || title } />
                <meta property="og:url" content={ location.href } />
                <meta property="og:image" content="https://cdn.elganso.com/media/scandiweb/images/ganso.png" />
            </>
        );
    }

    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderOgMeta() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
                <script className="structured-data-list" type="application/ld+json">{ this.renderStructured() }</script>
            </>
        );
    }

    render() {
        return createPortal(
            { ...this.renderMeta() },
            document.head
        );
    }
}

export default Meta;
