import Html from 'Component/Html';
import {
    CmsBlock as SourceCmsBlock
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.override.style';

/** @namespace Elganso/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    componentDidUpdate() {
        if (document.getElementsByClassName('gnt-accordion')[0]) {
            const coll = document.getElementsByClassName('collapsed');

            for (let i = 0; i < coll.length; i++) {
                coll[i].addEventListener('click', (e) => {
                    e.currentTarget.classList.toggle('active');
                    const content = e.currentTarget.parentNode.getElementsByClassName('collapse')[0];
                    if (content.style.display === 'block') {
                        content.style.display = 'none';
                    } else {
                        content.style.display = 'block';
                    }
                });
            }
        }
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            blockType
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        const contentHTML = content.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
              className={ identifier }
            >
                <Html content={ contentHTML } />
            </div>
        );
    }
}

export default CmsBlockComponent;
