import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';

import CustomerNotificationsQuery from 'Query/CustomerNotifications.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import { isSignedIn } from 'Util/Auth';
import { getDeviceType, isApp } from 'Util/Mobile/isMobile';
import { fetchMutation } from 'Util/Request';

import 'regenerator-runtime/runtime.js';

/** @namespace Elganso/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    googleConfigs: state.ConfigReducer?.googleConfigs,
    facebookPixelConfigs: state.ConfigReducer?.facebookPixelConfigs
});

/** @namespace Elganso/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Elganso/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    componentDidMount() {
        super.componentDidMount();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams !== null) {
            this.checkNotificacion(urlParams);
            this.loadTrazabilidad(urlParams);
        }
    }

    googleTagManagerConsentNotify() {
        const ga4consent = window.localStorage.getItem('ga4consent');
        if (window.localStorage.config !== undefined && ga4consent !== undefined && ga4consent !== null) {
            const localStorageConfig = JSON.parse(window.localStorage.config);
            const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
            let tagManagerArgs = {};
            const obj = JSON.parse(ga4consent);
            tagManagerArgs = {
                gtmId: gtmID,
                dataLayer: {
                    event: 'gtm_consent_update',
                    ad_storage: obj.ad_storage,
                    ad_user_data: obj.ad_user_data,
                    ad_personalization: obj.ad_personalization,
                    analytics_storage: obj.analytics_storage
                },
                dataLayerName: 'YeboyeboDataLayer'
            };
            TagManager.dataLayer(tagManagerArgs);
        }
    }

    componentDidUpdate(_prevProps, _prevState) {
        super.componentDidUpdate(_prevProps, _prevState);
        this.googleTagManagerConsentNotify();
    }

    loadTrazabilidad(urlParams) {
        const notifId = urlParams.get('notifId');
        const campaign = urlParams.get('utm_campaign');
        const esApp = isApp();
        const device = getDeviceType();
        let trazabilidadType = '';
        let trazabilidadValue = '';

        if (notifId !== null && notifId !== '') {
            trazabilidadType = 'Notificacion';
            trazabilidadValue = notifId;
        } else if (campaign !== null && campaign !== '') {
            trazabilidadType = 'Correo Salesmanago';
            trazabilidadValue = campaign;
        }

        if (trazabilidadType !== '' && trazabilidadValue !== '') {
            const obj = {
                tipo: trazabilidadType,
                valor: trazabilidadValue,
                app: esApp,
                dispositivo: device
            };

            sessionStorage.setItem('trazabilidadParams', JSON.stringify(obj));
        }
    }

    checkNotificacion(urlParams) {
        const notifId = urlParams.get('notifId');
        const customer = urlParams.get('customer');

        if (notifId !== null && notifId !== '') {
            if (isSignedIn()) {
                this.marcarComoLeido(notifId);
            } else if (customer !== null && customer !== '') {
                this.marcarComoLeido(notifId, customer);
            }
        }
    }

    async marcarComoLeido(idNotificacion, customer = null) {
        try {
            let mutation = null;
            const input = {
                id: idNotificacion
            };

            if (customer !== null) {
                input.user_id = customer;
            }

            mutation = CustomerNotificationsQuery.getMarcarNotificacionLeidoMutation(input);

            await fetchMutation(mutation);
        } finally {
            // console.log('PASAMOS');
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
