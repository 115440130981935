import Html from 'Component/Html';
import {
    FieldGroup as SourceFieldGroup
} from 'SourceComponent/FieldGroup/FieldGroup.component';

import './FieldGroup.override.style';

/** @namespace Elganso/Component/FieldGroup/Component */
export class FieldGroupComponent extends SourceFieldGroup {
    // Renders group label above field
    renderLabel() {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        const labelWrapper = `<div class="FieldGroup-Wrapper-Legend">${label}</div>`;

        return (<Html content={ labelWrapper } />);
    }
}

export default FieldGroupComponent;
