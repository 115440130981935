// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
import TagManager from 'react-gtm-module';
// import { isSignedIn } from 'Util/Auth';
// import getStore from 'Util/Store';

const googleWishlist = (args, callback, instance) => {
    const { isAddingWishlistItem: isPrevAddingWishlistItem } = args[0];
    const { isAddingWishlistItem, magentoProduct: [{ sku }] = [], productsInWishlist } = instance.props;

    if (isPrevAddingWishlistItem && !isAddingWishlistItem) {
        const wishlistProduct = Object.values(productsInWishlist).find(
            ({ sku: wishlistSku }) => sku === wishlistSku
        );
        const localStorageConfig = JSON.parse(window.localStorage.config);
        const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
        const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;

        if (wishlistProduct && gtm) {
            const currencyCode = localStorageConfig.data.currency.base_currency_code;
            const tagManagerArgs = {
                gtmId: gtmID,
                dataLayer: {
                    ecommerce: {
                        // eslint-disable-next-line object-shorthand
                        currencyCode: currencyCode,
                        // eslint-disable-next-line arrow-body-style
                        impressions: Object.values(productsInWishlist).map((item, index) => {
                            return {
                                id: item.sku,
                                name: item.name,
                                price: item.price_range.minimum_price.final_price.value,
                                // eslint-disable-next-line no-unused-vars
                                // categories: item.categories.map((cat, index) => cat.name).join(','),
                                position: index
                            };
                        })
                    }
                },
                dataLayerName: 'YeboyeboDataLayer'
            };

            TagManager.dataLayer(tagManagerArgs);
        }
    }

    return callback(...args);
};

export default {
    'Component/ProductWishlistButton/Container': {
        'member-function': {
            componentDidUpdate: googleWishlist
        }
    }
};
