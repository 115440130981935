import { PureComponent } from 'react';

import DisponibilidadTiendasQuery from 'Query/DisponibilidadTiendas.query';
import { ProductType } from 'Type/ProductList.type';
import { isMobile } from 'Util/Mobile';
import { fetchMutation } from 'Util/Request';

import DisponibilidadEnTienda from './DisponibilidadEnTienda.component';

/** @namespace Elganso/Component/DisponibilidadEnTienda/Container */
export class DisponibilidadEnTiendaContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    state = {
        currentZipCountry: '',
        currentSizeSelected: '',
        errorDisponibilidad: false,
        respuestaDisponibilidad: [],
        mensajeDisponibilidad: '',
        isLoading: false,
        showDisponibilidadTiendas: false,
        currentTab: 'LIST',
        visitMapStore: null
    };

    containerFunctions = {
        submitForm: this.submitForm.bind(this),
        onZipCodeChange: this.onZipCodeChange.bind(this),
        onSizeSelected: this.onSizeSelected.bind(this),
        clickShowDisponibilidadTiendas: this.clickShowDisponibilidadTiendas.bind(this),
        onClickTab: this.onClickTab.bind(this),
        onClickVisitMapStore: this.onClickVisitMapStore.bind(this)
    };

    containerProps() {
        const { product } = this.props;
        const {
            currentZipCountry,
            currentSizeSelected,
            errorDisponibilidad,
            respuestaDisponibilidad,
            mensajeDisponibilidad,
            isLoading,
            showDisponibilidadTiendas,
            currentTab,
            visitMapStore
        } = this.state;

        let image = '/media/scandiweb/images/cargando-app.gif';

        if (product.image) {
            image = product.image.url;
        }

        return {
            product,
            image,
            currentZipCountry,
            currentSizeSelected,
            errorDisponibilidad,
            respuestaDisponibilidad,
            mensajeDisponibilidad,
            isLoading,
            showDisponibilidadTiendas,
            currentTab,
            visitMapStore
        };
    }

    submitForm() {
        const { product } = this.props;
        const { currentZipCountry, currentSizeSelected } = this.state;

        if (currentZipCountry !== '') {
            this.setState({ isLoading: true });
            const disponibilidad = {
                sku: product.sku,
                talla: (currentSizeSelected === 0 ? '' : currentSizeSelected),
                ciudad_codpostal: currentZipCountry
            };

            this.consultarDisponibilidad(disponibilidad);
        }
    }

    async consultarDisponibilidad(disponibilidad) {
        const query = DisponibilidadTiendasQuery.getDisponibilidadProductoMutation(disponibilidad);

        let result = await fetchMutation(query);
        result = JSON.parse(result.consultarDisponibilidad.result);

        if (result.error === undefined) {
            this.setState({ errorDisponibilidad: false });
            this.setState({ respuestaDisponibilidad: result });
            this.setState({ mensajeDisponibilidad: __('We recommend calling the store first to ensure stock.') });
        } else {
            this.setState({ errorDisponibilidad: true });
            this.setState({ mensajeDisponibilidad: __('This product is no longer available in store') });
        }

        setTimeout(
            document.getElementsByClassName('aviso')[0].scrollIntoView({ behavior: 'smooth', block: 'center' }),
            5000
        );

        this.setState({ isLoading: false });
    }

    onZipCodeChange(event, field) {
        const { value: zipCountry = '' } = field || {};

        this.setState({ currentZipCountry: zipCountry });
    }

    onSizeSelected(event, field) {
        const { value: size = '' } = field || {};

        this.setState({ currentSizeSelected: size });
    }

    clickShowDisponibilidadTiendas() {
        const { showDisponibilidadTiendas } = this.state;

        if (!isMobile.any()) {
            if (!showDisponibilidadTiendas) {
                this.setState({ showDisponibilidadTiendas: true });

                document.getElementById('html-body').scrollTop = 0;

                // Ponemos timeout para que se ejecute el top antes de eliminar el scroll (falla en iOS)
                setTimeout(() => {
                    document.getElementById('html-body').style.overflowY = 'hidden';
                }, 200);
            } else {
                this.setState({ showDisponibilidadTiendas: false });

                document.getElementById('html-body').style.overflowY = 'overlay';
            }
        } else {
            this.setState({ showDisponibilidadTiendas: !showDisponibilidadTiendas });
        }
    }

    onClickTab(tab) {
        const { visitMapStore } = this.state;

        this.setState({ currentTab: tab });

        if (tab === 'LIST' && visitMapStore !== null) {
            this.setState({ visitMapStore: null });
        }
    }

    onClickVisitMapStore(store) {
        this.setState({ visitMapStore: store, currentTab: 'MAP' });
    }

    render() {
        return (
            <DisponibilidadEnTienda
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default DisponibilidadEnTiendaContainer;
