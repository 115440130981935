/* eslint-disable no-prototype-builtins */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';
import VideoVimeo from 'Component/VideoVimeo';
import { CATALOG_PRODUCT_LIST, CATALOG_PRODUCT_SLIDER } from 'Component/WidgetFactory/WidgetFactory.config';
import YBFormWebForm from 'Component/YBFormWebForm';
import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';
import { hash } from 'Util/Request/Hash';

import './Html.override.style';

export {
    WidgetFactory
};

/** @namespace Elganso/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        ...this.rules,
        {
            query: { name: ['div'] },
            replace: this.replaceForm
        },
        {
            query: { name: ['iframe'] },
            replace: this.replaceIframe
        }
    ];

    componentDidMount() {
        document.dispatchEvent(new Event('DOMContentLoaded'));
    }

    attributesToProps(attribs, parent = null) {
        const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());
        const replaceGuion = (string) => string.replaceAll('-', '_');

        const convertPropertiesToValidFormat = (properties) => Object.entries(properties)
            .reduce((validProps, [key, value]) => {
                let formattedKey = toCamelCase(key);
                formattedKey = replaceGuion(formattedKey);

                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value)) {
                    return { ...validProps, [formattedKey]: +value };
                }

                return { ...validProps, [formattedKey]: value };
            }, {});

        let properties = convertPropertiesToValidFormat(attribs);

        // Si es el widget de productos nos traemos las props del padre para diferenciar entre grid y widget
        if (attribs.type === CATALOG_PRODUCT_LIST) {
            const parentProperties = convertPropertiesToValidFormat(parent.attribs);

            if (parentProperties.data_appearance === 'carousel') {
                parentProperties.type = CATALOG_PRODUCT_SLIDER;
            }

            properties = { ...properties, ...parentProperties };
        }

        return attributesToProps(properties);
    }

    /**
     * Insert corresponding widget
     *
     * @param {{ attribs: Object }} { attribs }
     * @returns {null|JSX} Return Widget
     * @memberof Html
     */
    replaceWidget({ attribs, parent }) {
        return (
            <Suspense fallback={ <Loader isLoading /> }>
                <WidgetFactory { ...this.attributesToProps(attribs, parent) } />
            </Suspense>
        );
    }

    replaceScript(elem) {
        const { attribs, children } = elem;
        const { src = '' } = attribs;
        const scriptContent = children[0] ? children[0].data : '';
        const elemHash = hash(src + scriptContent);

        if (this.createdOutsideElements[elemHash]) {
            return null;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            if (!children[0].data.includes('require')) {
                script.appendChild(document.createTextNode(children[0].data));
            }
        }

        document.head.appendChild(script);

        this.createdOutsideElements[elemHash] = true;

        return null;
    }

    replaceForm({ attribs, children }) {
        const atributos = attribs;
        if (atributos.hasOwnProperty('data-content-type') && atributos['data-content-type'] === 'webforms_form') {
            // obtenemos el id del formulario
            const { content } = this.props;
            const htmlObject = document.createElement('div');
            htmlObject.innerHTML = content;
            const inputs = htmlObject.getElementsByTagName('input');
            let form_id = '';

            if (inputs.length > 0) {
                form_id = inputs.namedItem('form_id').value;
            }
            if (form_id !== '') {
                return (
                    <YBFormWebForm
                      form_id={ form_id }
                    />
                );
            }
        } else if (atributos.hasOwnProperty('data-background-images') && atributos.hasOwnProperty('data-pb-style')) {
            const imagenClassName = `background-image-${atributos['data-pb-style']}`;
            atributos.class = `${ atributos.class } ${ imagenClassName }`;

            const rawAttrValue = atributos['data-background-images'].replace(/\\(.)/mg, '$1');
            const attrValue = JSON.parse(rawAttrValue);

            const imagenDesktop = attrValue.desktop_image;
            const imagenMobile = attrValue.mobile_image;

            let estilo = '';

            if (imagenDesktop) {
                estilo = `.${imagenClassName} {`
                + `background-image: url(${imagenDesktop});`
                + '}';
            }

            if (imagenMobile) {
                estilo += '@media only screen and (max-width: 768px) and (min-width: 640px) {'
                    + `.${imagenClassName} {`
                        + `background-image: url(${imagenMobile});`
                    + '}'
                + '}'
                + '@media only screen and (max-width: 640px) {'
                    + `.${imagenClassName} {`
                    + `    background-image: url(${imagenMobile});`
                    + '}'
                + '}';
            }

            return (
                <>
                    <div { ...attributesToProps(atributos) }>
                        { domToReact(children, this.parserOptions) }
                    </div>
                    <style type="text/css">
                        { estilo }
                    </style>
                </>
            );
        }

        return null;
    }

    replaceIframe(elem) {
        const atributos = elem.attribs;

        if (atributos['data-element'] === 'video') {
            if (atributos.src.includes('vimeo.com') || atributos.src_mobile.includes('vimeo.com')) {
                let url = '';
                let url_mobile = '';

                if (atributos.src !== '' && atributos.src !== undefined && atributos.src !== null) {
                    url = atributos.src.replace(/\?autoplay=1&mute=1/g, '');
                    url = url.replace(/&autoplay=1&mute=1/g, '');
                    url = url.replace(/\?title=0&byline=0&portrait=0/g, '');
                    url = url.replace(/&autoplay=1&autopause=0&muted=1/g, '');
                    url = url.replace(/&loop=1/g, '');
                }
                if (atributos.src_mobile !== '' && atributos.src_mobile !== undefined && atributos.src_mobile !== null) {
                    url_mobile = atributos.src_mobile.replace(/\?autoplay=1&mute=1/g, '');
                    url_mobile = url_mobile.replace(/&autoplay=1&mute=1/g, '');
                    url_mobile = url_mobile.replace(/\?title=0&byline=0&portrait=0/g, '');
                    url_mobile = url_mobile.replace(/&autoplay=1&autopause=0&muted=1/g, '');
                    url_mobile = url_mobile.replace(/&loop=1/g, '');
                }

                const isAutoplay = atributos.autoplay === 'true';
                const isLoop = atributos.loop === 'true';

                return (
                    <VideoVimeo
                      src={ url }
                      src_mobile={ url_mobile }
                      isAutoplay={ isAutoplay }
                      isMuted={ isAutoplay }
                      isLoop={ isLoop }
                    />
                );
            }
        }

        return null;
    }
}

export default Html;
