import {
    CART as SOURCE_CART,
    CART_EDITING as SOURCE_CART_EDITING,
    CART_OVERLAY as SOURCE_CART_OVERLAY,
    CATEGORY as SOURCE_CATEGORY,
    CHECKOUT as SOURCE_CHECKOUT,
    CHECKOUT_ACCOUNT as SOURCE_CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS as SOURCE_CHECKOUT_SUCCESS,
    CMS_PAGE as SOURCE_CMS_PAGE,
    CONTACT_US as SOURCE_CONTACT_US,
    CUSTOMER_ACCOUNT as SOURCE_CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE as SOURCE_CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_ORDER as SOURCE_CUSTOMER_ORDER,
    CUSTOMER_SUB_ACCOUNT as SOURCE_CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST as SOURCE_CUSTOMER_WISHLIST,
    FILTER as SOURCE_FILTER,
    HOME_PAGE as SOURCE_HOME_PAGE,
    MENU as SOURCE_MENU,
    MENU_SUBCATEGORY as SOURCE_MENU_SUBCATEGORY,
    MY_ACCOUNT as SOURCE_MY_ACCOUNT,
    NO_MATCH as SOURCE_NO_MATCH,
    PDP as SOURCE_PDP,
    POPUP as SOURCE_POPUP,
    PRODUCT_COMPARE as SOURCE_PRODUCT_COMPARE,
    SEARCH as SOURCE_SEARCH
} from 'SourceComponent/Header/Header.config';

// TODO: implement PDP
export const PDP = SOURCE_PDP;

// TODO: implement POPUP
export const POPUP = SOURCE_POPUP;

// TODO: implement CATEGORY
export const CATEGORY = SOURCE_CATEGORY;

// TODO: implement CUSTOMER_ACCOUNT
export const CUSTOMER_ACCOUNT = SOURCE_CUSTOMER_ACCOUNT;

// TODO: implement CUSTOMER_SUB_ACCOUNT
export const CUSTOMER_SUB_ACCOUNT = SOURCE_CUSTOMER_SUB_ACCOUNT;

// TODO: implement CUSTOMER_ACCOUNT_PAGE
export const CUSTOMER_ACCOUNT_PAGE = SOURCE_CUSTOMER_ACCOUNT_PAGE;

// TODO: implement CUSTOMER_WISHLIST
export const CUSTOMER_WISHLIST = SOURCE_CUSTOMER_WISHLIST;

// TODO: implement CUSTOMER_ORDER
export const CUSTOMER_ORDER = SOURCE_CUSTOMER_ORDER;

// TODO: implement HOME_PAGE
export const HOME_PAGE = SOURCE_HOME_PAGE;

// TODO: implement MENU
export const MENU = SOURCE_MENU;

// TODO: implement MENU_SUBCATEGORY
export const MENU_SUBCATEGORY = SOURCE_MENU_SUBCATEGORY;

// TODO: implement SEARCH
export const SEARCH = SOURCE_SEARCH;

// TODO: implement FILTER
export const FILTER = SOURCE_FILTER;

// TODO: implement CART
export const CART = SOURCE_CART;

// TODO: implement CART_OVERLAY
export const CART_OVERLAY = SOURCE_CART_OVERLAY;

// TODO: implement CART_EDITING
export const CART_EDITING = SOURCE_CART_EDITING;

// TODO: implement CHECKOUT
export const CHECKOUT = SOURCE_CHECKOUT;

// TODO: implement CHECKOUT_SUCCESS
export const CHECKOUT_SUCCESS = SOURCE_CHECKOUT_SUCCESS;

// TODO: implement CHECKOUT_ACCOUNT
export const CHECKOUT_ACCOUNT = SOURCE_CHECKOUT_ACCOUNT;

// TODO: implement CMS_PAGE
export const CMS_PAGE = SOURCE_CMS_PAGE;

// TODO: implement MY_ACCOUNT
export const MY_ACCOUNT = SOURCE_MY_ACCOUNT;

// TODO: implement NO_MATCH
export const NO_MATCH = SOURCE_NO_MATCH;

// TODO: implement CONTACT_US
export const CONTACT_US = SOURCE_CONTACT_US;

// TODO: implement PRODUCT_COMPARE
export const PRODUCT_COMPARE = SOURCE_PRODUCT_COMPARE;

export const ONE_MONTH_IN_SECONDS = 2628000;
