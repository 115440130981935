import {
    faAddressBook, faLocationDot, faMinus, faPhone, faPlus
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './ItemListTiendaDisponibilidad.style';

/** @namespace Elganso/Component/ItemListTiendaDisponibilidad/Component */
export class ItemListTiendaDisponibilidad extends PureComponent {
    render() {
        const {
            clicShowInfoShop, itemOpened, onClickVisitMapStore, product, tienda
        } = this.props;

        let tallas = null;

        if (product.type_id === 'configurable') {
            tallas = `<p class="tallas"><strong>${ __('Available sizes') }: </strong>`;

            tienda.tallas.forEach((talla, id) => {
                if (tienda.tallas.length === 1 || id === tienda.tallas.length - 1) {
                    tallas += `${ talla }`;
                } else {
                    tallas += `${ talla }, `;
                }
            });

            tallas += '</p>';
        }

        return (
            <div block="ItemListTiendaDisponibilidad">
                <FontAwesomeIcon className={ itemOpened ? 'iconShowInfo closed' : 'iconShowInfo opened' } icon={ faPlus } onClick={ clicShowInfoShop } />
                <FontAwesomeIcon className={ itemOpened ? 'iconShowInfo opened' : 'iconShowInfo closed' } icon={ faMinus } onClick={ clicShowInfoShop } />
                <div>
                    <p>
                        <strong>
                            { `${ tienda.nombre } - ${ tienda.ciudad } (${ tienda.provincia })` }
                        </strong>
                    </p>
                    <div className={ itemOpened ? 'opened' : 'closed' }>
                        <div className="addressLine">
                            <FontAwesomeIcon className="addressIcon" icon={ faAddressBook } />
                            <div>
                                <p>
                                    { tienda.direccion } <br />
                                    { `${ tienda.codpostal } - ${ tienda.ciudad } (${ tienda.provincia })` }
                                </p>
                            </div>
                        </div>
                        { tienda.telefono
                            ? (
                            <p>
                                { /* eslint-disable-next-line react/forbid-elements */ }
                                <a href={ `tel:${ tienda.telefono }` }>
                                    <FontAwesomeIcon className="phoneIcon" icon={ faPhone } />{ tienda.telefono }
                                </a>
                            </p>
                            ) : '' }
                        <p>
                            <span
                              className="toMapButton"
                              onClick={ () => onClickVisitMapStore(tienda.codAlmacen) }
                              onKeyDown={ () => onClickVisitMapStore(tienda.codAlmacen) }
                            >
                                <FontAwesomeIcon className="locationIcon" icon={ faLocationDot } />{ __('See on map') }
                            </span>
                        </p>
                    </div>
                    { product.type_id === 'configurable'
                        ? <Html content={ tallas } />
                        : null }
                </div>
            </div>
        );
    }
}

export default ItemListTiendaDisponibilidad;
