/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import TagManager from 'react-gtm-module';

const gogleTagManagerCreateAccountData = (args, callback) => {
    const localStorageConfig = JSON.parse(window.localStorage.config);
    const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
    const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;

    if (gtm) {
        const tagManagerArgs = {
            gtmId: gtmID,
            dataLayer: {
                event: 'sign_up',
                method: 'ElGanso'
            },
            dataLayerName: 'YeboyeboDataLayer'
        };

        TagManager.dataLayer(tagManagerArgs);
    }

    return callback(...args);
};

export default {
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onSuccess: gogleTagManagerCreateAccountData
        }
    }
};
