/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import ReactPixel from 'react-facebook-pixel';

import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

function stripNonNumeric(str) {
    if (!str) {
        return null;
    }

    return str.replace('/[^\p{N}]+/', '');
}

function stringToHash(string) {
    let hash = 0;

    if (string.length == 0) {
        return hash;
    }

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return String(hash);
}

function generateEventId(eName) {
    const uCookie = document.cookie;
    const uHash = stringToHash(uCookie);
    const url = window.location.href;
    const urlHash = stringToHash(url);

    function getTimeStamp() {
        if (!Date.now) {
            Date.now = () => {
                new Date().getTime();
            };
        }

        return Date.now();
    }

    const timestamp = String(getTimeStamp());

    return eName + uHash + urlHash + timestamp;
}

const initializeFacebookBusinessExtension = (args, callback, instance) => {
    const { facebookPixelConfigs } = instance.props;
    const { fbq } = window;

    if (facebookPixelConfigs?.facebookPixelID && !fbq && facebookPixelConfigs?.isEnabled) {
        let advancedMatching = {};

        if (isSignedIn()) {
            const customer = BrowserDatabase.getItem(CUSTOMER);
            const defaultAddress = customer?.addresses.find(addr => addr.default_billing);
            let gender = '';

            if (customer?.gender === 1) {
                gender = 'm';
            } else if (customer?.gender === 2) {
                gender = 'f';
            }

            advancedMatching = {
                em: customer?.email,
                ph: stripNonNumeric(defaultAddress?.telephone),
                fn: customer?.firstname,
                ln: customer?.lastname,
                ge: gender,
                db: stripNonNumeric(customer?.dob),
                ct: defaultAddress?.city,
                st: defaultAddress?.street.join(),
                zp: defaultAddress?.postcode,
                country: defaultAddress?.region.region,
                external_id: customer?.id
            }
        }
        const options = {
            autoConfig: true,
            debug: false
        };

        ReactPixel.init(facebookPixelConfigs.facebookPixelID, advancedMatching, options);
        ReactPixel.pageView();
    }

    return callback(...args);
};

export default {
    'Component/Router/Container': {
        'member-function': {
            render: initializeFacebookBusinessExtension
        }
    }
};
