import DisponibilidadEnTienda from 'Component/DisponibilidadEnTienda';
import ProductBci from 'Component/ProductBci';
import { showPopup } from 'Store/Popup/Popup.action';
import getStore from 'Util/Store';

import YeboyeboEnvioYDevoluciones from '../component/YeboyeboEnvioYDevoluciones/YeboyeboEnvioYDevoluciones.component';
import YeboyeboProducts from '../component/YeboyeboProducts/YeboyeboProducts.component';

export const PRODUCT_COMPOSICION_LAVADO_TAB = 'PRODUCT_COMPOSICION_LAVADO_TAB';
export const ENVIOS_Y_DEVOLUCIONES_TAB = 'ENVIOS_Y_DEVOLUCIONES_TAB';
export const PRODUCT_DISPONIBILIDAD_TIENDA_TAB = 'PRODUCT_DISPONIBILIDAD_TIENDA_TAB';
export const PRODUCT_BCI_TAB = 'PRODUCT_BCI_TAB';

function showPopupEnvios() {
    const store = getStore();
    const { dispatch } = store;
    dispatch(showPopup('envios_y_devoluciones', { title: __('ENVIOS Y DEVOLUCIONES') }));
}

export const tabMap = (member, instance) => ({
    ...member,
    [PRODUCT_COMPOSICION_LAVADO_TAB]: {
        ...instance,
        name: __('Composición y Lavado'),
        shouldTabRender: () => {
            const { isComposicionLavadoTabEmpty } = instance.props;
            return !isComposicionLavadoTabEmpty;
        },
        render: (key) => {
            const { activeProduct: { attributes } = {} } = instance.props;
            // eslint-disable-next-line max-len
            return (
                <YeboyeboProducts
                  key={ key }
                  composicion_textil={ attributes?.composicion_textil }
                  lavado={ attributes?.lavado }
                />
            );
        }
    },
    // Falta detectar si el bloque CMS existe, esta activo y tiene contendo para renderizar
    [ENVIOS_Y_DEVOLUCIONES_TAB]: {
        ...instance,
        name: __('Envios Y Devoluciones'),
        shouldTabRender: () => true,
        render: (key) => (
            <YeboyeboEnvioYDevoluciones
              key={ key }
              showPopup={ showPopupEnvios }
            />
        )
    },
    [PRODUCT_BCI_TAB]: {
        ...instance,
        name: 'Better Cotton Initiative',
        shouldTabRender: () => {
            const { isProductBciEmpty } = instance.props;
            return !isProductBciEmpty;
        },
        render: (key) => {
            const { activeProduct: { attributes } = {} } = instance.props;
            return (
                <ProductBci
                  key={ key }
                  es_bci={ attributes?.es_bci }
                />
            );
        }
    },
    [PRODUCT_DISPONIBILIDAD_TIENDA_TAB]: {
        ...instance,
        name: __('Availability in stores'),
        shouldTabRender: () => {
            const { isProductDisponibilidadTiendaTabEmpty } = instance.props;
            return isProductDisponibilidadTiendaTabEmpty;
        },
        render: (key) => {
            const { activeProduct } = instance.props;

            return (
                <DisponibilidadEnTienda
                  key={ key }
                  product={ activeProduct }
                />
            );
        }
    }
});

export default {
    'Route/ProductPage/Component': {
        'member-property': { tabMap }
    }
};
