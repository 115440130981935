/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import TagManager from 'react-gtm-module';

const gogleTagManagerCheckoutCartData = (args, callback, instance) => {
    // var gtm = window.google_tag_manager['GTM-WPPZ3P7'];
    // if (gtm) {
    //     const { checkoutStep, checkoutTotals }= instance.props;
    //     if (checkoutTotals.items) {
    //         console.log("GTM checkout tep");
    //         const category = "";
    //         const products = checkoutTotals.items.map((item, index) => { return {id: item.product.sku, name: item.product.name, price: item.prices.price.value, category: category, qty: item.quantity, position: index} });
            
    //         const tagManagerArgs = {
    //             gtmId: 'GTM-WPPZ3P7',
    //             dataLayer: {
    //                 ecommerce: {
    //                     checkout: {
    //                         products: products,
    //                         actionField: {
    //                             step: 1,
    //                             option: "Cart"
    //                         }
    //                     }
    //                 }
    //             },
    //             dataLayerName: 'YeboyeboDataLayer'
    //         }
    //         TagManager.dataLayer(tagManagerArgs);
    //     }

    // }
    return callback(...args);
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount: gogleTagManagerCheckoutCartData
        }
    }
};
