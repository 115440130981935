/* eslint-disable max-len */
/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
*/

import TagManager from 'react-gtm-module';

const googleTagCategoryEvent = (args, callback, instance) => {
    if (window.localStorage.config !== undefined) {
        const localStorageConfig = JSON.parse(window.localStorage.config);
        const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
        const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;
        if (gtm) {
            const tagManagerArgs = {
                gtmId: gtmID,
                dataLayer: {
                    event: 'gtm.js',
                    ecommerce: {
                        impressions: instance.props.items.map((item, index) => ({
                            id: item.sku,
                            name: item.name,
                            position: index
                        }))
                    }
                },
                dataLayerName: 'YeboyeboDataLayer'
            };

            TagManager.dataLayer(tagManagerArgs);
        }
    }

    return callback(...args);
};

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            renderPageItems: googleTagCategoryEvent
        }
    }
};
