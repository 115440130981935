/**
 * Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/googletagmanager/Query/PaypalConfigs/Query */
export class recomendadosCarritoConfigQuery {
    getQuery() {
        return new Field('recomendadosCarritoConfig')
            .addFieldList(this.getrecomendadosCarritoConfigFields());
    }

    getrecomendadosCarritoConfigFields() {
        return [
            'isEnabled',
            'title'
        ];
    }
}

export default new recomendadosCarritoConfigQuery();
