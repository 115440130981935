import {
    CART_ID as SOURCE_CART_ID,
    deleteCartId as SourceDeleteCartId,
    setCartId as SourceSetCartId
} from 'SourceUtil/Cart/Token';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export const CART_ID = SOURCE_CART_ID;

export const setCartId = SourceSetCartId;

// export const getCartId = SourceGetCartId;

/** @namespace Elganso/Util/Cart/Token/getCartId */
export const getCartId = () => {
    const { website_code } = window;

    const tokens = BrowserDatabase.getItem(CART_ID) || {};

    const token = tokens[website_code];

    if (token) {
        if (token.isCustomerToken && !isSignedIn()) {
            return '';
        }

        return token.token;
    }

    return '';
};

export const deleteCartId = SourceDeleteCartId;
