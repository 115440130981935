import {
    ClickOutside as SourceClickOutside
} from 'SourceComponent/ClickOutside/ClickOutside.component';
import { isMobile } from 'Util/Mobile';

import './ClickOutside.override.style';

/** @namespace Elganso/Component/ClickOutside/Component */
export class ClickOutsideComponent extends SourceClickOutside {
    handleClick({ target }) {
        const { onClick } = this.props;
        if (this.props?.isMiniCart && isMobile.any() && document.getElementsByClassName('CartOverlay_isVisible')[0]) {
            if (!target.parentNode.classList.contains('Header-MinicartButtonWrapper')) {
                if (!document.getElementsByClassName('CartOverlay_isVisible')[0].contains(target)) {
                    onClick();
                }
            }
        } else if (this.childrenRefs.every(
            (ref) => {
                const elementRef = ref.current?.overlayRef?.current || ref.current;
                return !elementRef.contains(target);
            }
        )) {
            onClick();
        }
    }
}

export default ClickOutsideComponent;
