import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import { isCrawler, isSSR } from 'Util/Browser';

import './ExpandableContent.override.style';

/** @namespace Elganso/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        ...this.propTypes,
        heading: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        onToggleExpand: PropTypes.func
    };

    __construct(props) {
        super.__construct(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler() || this.props.mix.block === 'ProductInformation';

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            prevIsContentExpanded: isContentExpanded
        };
    }

    toggleExpand() {
        const { onClick, onToggleExpand, mix } = this.props;

        if (onClick) {
            onClick();

            return;
        }
        if (onToggleExpand) {
            onToggleExpand(mix);
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent()
        );
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        // if (!isMobile) {
        //     return null;
        // }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                { this.renderButtonIcon() }
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </div>
            </div>
        );
    }

    render() {
        const { mix, mods } = this.props;

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;
