import {
    FieldGroupContainer as SourceFieldGroupContainer
} from 'SourceComponent/FieldGroup/FieldGroup.container';

/** @namespace Elganso/Component/FieldGroup/Container */
export class FieldGroupContainer extends SourceFieldGroupContainer {
    // TODO implement logic
}

export default FieldGroupContainer;
