import { connect } from 'react-redux';

import { TYPE_PRODUCT } from 'SourceRoute/UrlRewrites/UrlRewrites.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace Elganso/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Elganso/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Elganso/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    containerProps() {
        const { urlRewrite: { additional_data } } = this.props;

        return {
            ...super.containerProps(),
            addtitionalData: additional_data
        };
    }

    componentDidUpdate() {
        const url_type = this.getType();

        if (url_type !== TYPE_PRODUCT) { // Previene error 404 en ficha de producto
            super.componentDidUpdate();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
