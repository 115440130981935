import { faHeadset, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PureComponent } from 'react';

import { isApp } from 'Util/Mobile/isMobile';

import './YBChatbot.style';

/** @namespace Elganso/Component/YBChatbot/Component */
export class YBChatbot extends PureComponent {
    render() {
        const {
            onClickButtonChat, openedChatbot, showChatbot, urlChatbot
        } = this.props;

        if (showChatbot && urlChatbot !== null) {
            return (
                <div block="YBChatbot">
                    <span
                      block="YBChatbot"
                      elem="CloseButton"
                      onClick={ onClickButtonChat }
                      onKeyDown={ onClickButtonChat }
                      className={ openedChatbot ? 'chatbotOpen' : 'chatbotClose' }
                    >
                        <FontAwesomeIcon
                          className="YBChatbot-ImageButton"
                          icon={ faXmark }
                        />
                    </span>
                    <span
                      block="YBChatbot"
                      elem="Button"
                      onClick={ onClickButtonChat }
                      onKeyDown={ onClickButtonChat }
                      className={ isApp() ? 'chatbotApp' : '' }
                    >
                        <FontAwesomeIcon
                          className="YBChatbot-ImageButton"
                          icon={ faHeadset }
                        />
                    </span>
                    <iframe
                      src={ urlChatbot }
                      title="GANSITO"
                      className={ openedChatbot ? 'chatbotOpen' : 'chatbotClose' }
                      block="YBChatbot"
                      elem="Iframe"
                    />
                </div>
            );
        }

        return null;
    }
}

export default YBChatbot;
