import {
    isMobileClientHints as SourceIsMobileClientHints,
    isUsingClientHints as SourceIsUsingClientHints
} from 'SourceUtil/Mobile/isMobile';

export const isUsingClientHints = SourceIsUsingClientHints;

export const isMobileClientHints = SourceIsMobileClientHints;

/** @namespace Elganso/Util/Mobile/isMobile/isApp */
export const isApp = () => window.matchMedia('(max-width: 768px)').matches && (navigator.userAgent.search('app-elganso') !== -1 || navigator.userAgent.search('app-pwa') !== -1);

export const isMobile = {
    android: (agent = navigator.userAgent) => /android/i.test(agent),
    blackBerry: (agent = navigator.userAgent) => /blackberry/i.test(agent),
    iOS: (agent = navigator.userAgent) => {
        if (isApp()) {
            return /ios/i.test(agent);
        }

        return /iphone|ipod|ipad/i.test(agent);
    },
    opera: (agent = navigator.userAgent) => /opera mini/i.test(agent),
    // see https://developer.chrome.com/docs/multidevice/user-agent/ for details
    safari: (agent = navigator.userAgent) => /safari/i.test(agent)
        && !/chrome/i.test(agent)
        && !/CriOS/i.test(agent)
        && !/FxiOS/i.test(agent),
    windows: (agent = navigator.userAgent) => /iemobile/i.test(agent),
    // iPad uses 810 so we need to handle that.
    any: () => window.matchMedia('(max-width: 810px)').matches && window.matchMedia('screen').matches,
    standaloneMode: () => window.matchMedia('(display-mode: standalone)').matches
};

/** @namespace Elganso/Util/Mobile/isMobile/getDeviceType */
export const getDeviceType = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
    const { userAgent, userAgentData = null } = window.navigator;
    let deviceType = 'unknown';

    // Si es app lo extraemos del UserAgent si no viene en el user agent utilizamos la funcionalidad del util
    if (userAgent.includes('app-pwa') || userAgent.includes('app-elganso')) {
        let arrayUA = userAgent;

        if (arrayUA.startsWith('App-elganso:') || arrayUA.startsWith('app-elganso:') || arrayUA.startsWith('app-pwa:')) {
            arrayUA = arrayUA.replace(/-elganso:/gi, '-elganso|');
            arrayUA = arrayUA.replace(/-pwa:/gi, '-pwa|');
            arrayUA = arrayUA.replace(/:/gi, '|');
        }

        arrayUA = arrayUA.split('|');

        if (arrayUA.length > 1) {
            deviceType = `app-pwa | ${arrayUA[3]} | ${arrayUA[2]}`;
        } else if (isMobile.iOS()) {
            deviceType = 'app-pwa | iOS';
        } else if (isMobile.android()) {
            deviceType = 'app-pwa | Android';
        } else {
            deviceType = 'app-pwa';
        }
    } else if (userAgent.includes('Firefox')) {
        let version = userAgent.split('Firefox/')[1];
        if (version !== undefined && version !== null) {
            version = version.split(' ')[0];
        } else {
            version = '1';
        }

        deviceType = `Firefox v${version}`;
    } else if (userAgent.includes('SamsungBrowser')) {
        let version = userAgent.split('SamsungBrowser/')[1];
        if (version !== undefined && version !== null) {
            version = version.split(' ')[0];
        } else {
            version = '1';
        }

        if (userAgentData !== null) {
            deviceType = `Samsung Browser v${version} | ${userAgentData.platform}`;
        } else {
            deviceType = `Samsung Browser v${version}`;
        }
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
        if (userAgentData !== null) {
            const obj = userAgentData.brands.slice(-1)[0];
            const brand = 'Opera';
            const version = '1.0';
            if (obj !== undefined) {
                const { brand, version } = obj;
                deviceType = `${brand} v${version} | ${userAgentData.platform}`;
            }

            deviceType = `${brand} v${version} | ${userAgentData.platform}`;
        } else {
            let version = userAgent.split('OPR/')[1];
            if (version !== undefined && version !== null) {
                version = version.split(' ')[0];
            } else {
                version = '1';
            }

            deviceType = `Opera v${version}`;
        }
    } else if (userAgent.includes('Edg')) {
        if (userAgentData !== null) {
            const obj = userAgentData.brands.slice(-1)[0];
            const brand = 'Eddge Generico';
            const version = '1.0';
            if (obj !== undefined) {
                const { brand, version } = obj;
                deviceType = `${brand} v${version} | ${userAgentData.platform}`;
            }

            deviceType = `${brand} v${version} | ${userAgentData.platform}`;
        } else {
            let version = userAgent.split('Edg/')[1];
            if (version !== undefined && version !== null) {
                version = version.split(' ')[0];
            } else {
                version = '1';
            }

            deviceType = `Microsoft Edge v${version}`;
        }
    } else if (userAgent.includes('Chrome')) {
        if (userAgentData !== null) {
            const obj = userAgentData.brands.slice(-1)[0];
            const brand = 'Chrome Generico';
            const version = '1.0';
            if (obj !== undefined) {
                const { brand, version } = obj;
                deviceType = `${brand} v${version} | ${userAgentData.platform}`;
            }
            deviceType = `${brand} v${version} | ${userAgentData.platform}`;
        } else {
            let version = userAgent.split('Chrome/')[1];
            if (version !== undefined && version !== null) {
                version = version.split(' ')[0];
            } else {
                version = '1';
            }

            deviceType = `Google Chrome v${version}`;
        }
    } else if (userAgent.includes('Safari')) {
        let version = userAgent.split('Version/')[1];
        if (version !== undefined && version !== null) {
            version = version.split(' ')[0];
        } else {
            version = '1';
        }

        deviceType = `Apple Safari v${version}`;
    }

    return deviceType;
};

export default isMobile;
