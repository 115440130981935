/**
 * Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/googletagmanager/Query/PaypalConfigs/Query */
export class hrefLangConfigQuery {
    getQuery() {
        return new Field('hrefLangConfig')
            .addFieldList(this.gethrefLangConfigFields());
    }

    gethrefLangConfigFields() {
        return [
            'hrefLangBase'
        ];
    }
}

export default new hrefLangConfigQuery();
