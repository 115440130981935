import {
    VALIDATION_INPUT_TYPE as SOURCE_VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_INPUT as SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    VALIDATION_INPUT_TYPE_NUMBER as SOURCE_VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_TEXT as SOURCE_VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES
} from 'SourceUtil/Validator/Config';

export const VALIDATION_INPUT_TYPE_TEXT = SOURCE_VALIDATION_INPUT_TYPE_TEXT;

export const VALIDATION_INPUT_TYPE_NUMBER = SOURCE_VALIDATION_INPUT_TYPE_NUMBER;

export const VALIDATION_INPUT_TYPE_INPUT = SOURCE_VALIDATION_INPUT_TYPE_INPUT;

export const VALIDATION_INPUT_TYPE = SOURCE_VALIDATION_INPUT_TYPE;

export const VALIDATION_MESSAGES = SOURCE_VALIDATION_MESSAGES;

export const VALIDATION_RULES = {
    SOURCE_VALIDATION_RULES,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,6}$/im
};
