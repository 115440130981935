/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-console */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import StripeMoneyFormat from 'stripe-money-format';

// vvv Using to wait for store value
import getStore from 'SourceUtil/Store';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import StripePaymentsConfigQuery from '../../query/StripePaymentsConfig.query';
import { Payments } from '../../util/compat/payments';
import { waitForCallback } from '../../util/wait';
import StripePayments from './StripePayments.component';
import {
    STRIPE_BILLING_SUBMIT_EVENT,
    STRIPE_NEW_PAYMENT_METHOD,
    STRIPE_ORDER_PLACED_EVENT,
    STRIPE_PAYMENTS_ELEMENT_ID
} from './StripePayments.config';

/** @namespace Scandiweb/Stripe/Component/StripePayments/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandiweb/Stripe/Component/StripePayments/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Scandiweb/Stripe/Component/StripePayments/Container */
export class StripePaymentsContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired
    };

    state = {
        currentDropdownValue: STRIPE_NEW_PAYMENT_METHOD,
        paymentsConfig: { savedMethods: [] },
        isFormComplete: false,
        isCurrentlyLoading: false
    };

    containerFunctions = {
        setCurrentDropdownValue: this.setCurrentDropdownValue.bind(this)
    };

    componentDidMount() {
        document.addEventListener(STRIPE_BILLING_SUBMIT_EVENT, this.onBillingSubmit);
        document.addEventListener(STRIPE_ORDER_PLACED_EVENT, this.onOrderPlaced);
    }

    componentDidUpdate(_, prevState) {
        const { currentDropdownValue } = this.state;
        const { currentDropdownValue: prevDropdownValue } = prevState;

        if (
            currentDropdownValue !== prevDropdownValue
            && currentDropdownValue === STRIPE_NEW_PAYMENT_METHOD
            // ^^^ aka. "isPaymentFormVisible" changed
        ) {
            this.initPaymentForm();
        }
    }

    componentWillUnmount() {
        document.removeEventListener(STRIPE_BILLING_SUBMIT_EVENT, this.onBillingSubmit);
        document.removeEventListener(STRIPE_ORDER_PLACED_EVENT, this.onOrderPlaced);
    }

    initPaymentForm = async () => {
        const cartTotals = getStore().getState().CartReducer?.cartTotals;

        this.setState({ isFormComplete: false });
        // ^^^ Reset payment form status on init

        // ^^^ Init stripe if not saved
        this.stripe = new Payments();
        await this.stripe.init();

        // ^^^ Init elements if not saved
        const { locale } = await this.stripe.getConfig();
        this.elements = this.stripe.stripeJs.elements({
            locale,
            paymentMethodCreation: 'manual',
            currency: cartTotals?.prices?.quote_currency_code.toLowerCase(),
            mode: 'payment',
            amount: StripeMoneyFormat.toStripeFormat(cartTotals?.prices?.grand_total?.value)
        });

        const pElementOptions = {
            // vvv Original extension disabled wallets only if express checkout was disabled
            //     we disabled them, because of "Please fill in your card details." error
            wallets: {
                applePay: 'never',
                googlePay: 'never'
            }
            // TODO: handle fields (what to collect?)
            // ^^^ Tried, very difficult due to billing address being not controlled
        };

        // vvv Passing no options, original extensions passes some address fields
        const pElement = this.elements.create('payment', pElementOptions);
        pElement.mount(`#${STRIPE_PAYMENTS_ELEMENT_ID}`);
        pElement.on('change', ({ complete }) => {
            this.setState({ isFormComplete: !!complete });
        });
    };

    onBillingSubmit = async () => {
        const { showErrorNotification } = this.props;

        const {
            isFormComplete,
            currentDropdownValue,
            paymentsConfig: { savedMethods }
        } = this.state;

        try {
            if (!isFormComplete && currentDropdownValue === STRIPE_NEW_PAYMENT_METHOD) {
                throw new Error(__('Please complete your payment details.'));
            }

            // TODO: validate CVC (agreement is handled automatically)

            this.setState({ isCurrentlyLoading: true });
            if (currentDropdownValue === STRIPE_NEW_PAYMENT_METHOD) {
                // Si es una tarjeta nueva, hay que crearla en stripe
                const { elements } = this;
                const { stripeJs } = this.stripe;

                elements.submit().then(
                    /** @namespace Scandiweb/Stripe/Component/StripePayments/Container/StripePaymentsContainer/submit/then */
                    () => {
                        stripeJs.createPaymentMethod({
                            elements,
                            params: {
                            }
                        }).then(
                            /** @namespace Scandiweb/Stripe/Component/StripePayments/Container/StripePaymentsContainer/submit/then/createPaymentMethod/then */
                            (result) => {
                                const {
                                    paymentMethod: {
                                        type
                                    }
                                } = result;

                                if (type === 'klarna') {
                                    localStorage.setItem('paymentType', 'klarna');
                                }
                                // Actualizo los datos que utilizará stripe para finalizar la venta con la tarjeta creada
                                window.stripeData = {
                                    cvc_token: null,
                                    save_payment_method: true,
                                    payment_method: result.paymentMethod.id
                                };
                            }
                        );
                    },
                    /** @namespace Scandiweb/Stripe/Component/StripePayments/Container/StripePaymentsContainer/submit/then/catch */
                    (result) => {
                        if (result.error) {
                            console.error(result.error.message);
                        } else {
                            console.log(result);
                        }
                    }
                );

                return;
            }

            const { value } = savedMethods.find(({ fingerprint }) => (
                fingerprint === currentDropdownValue
            )) || {};

            if (!value) {
                throw new Error(__('Can not proceed with selected payment method.'));
            }
            // Configuración que se envia de una tarjeta guardada
            window.stripeData = {
                cvc_token: null,
                save_payment_method: true,
                payment_method: value
            };
        } catch (e) {
            this.setState({ isCurrentlyLoading: false });
            showErrorNotification(e.message);
        }
    };

    onOrderPlaced = async (ev) => {
        const { detail: { orderId } } = ev;

        try {
            if (!orderId) {
                // eslint-disable-next-line max-len
                throw new Error(__('The order was placed but the response from the server did not include a numeric order ID.'));
            }
        } catch (e) {
            console.log(e);
        }
        this.setState({ isCurrentlyLoading: false });
        this.onSuccess();
    };

    onSuccess() {
        const { paymentsConfig: { successUrl } } = this.state;
        window.location = successUrl;
    }

    onFatalError(e) {
        const cartRedirectUrl = new URL(window.location.href);
        cartRedirectUrl.pathname = '/checkout/success';
        cartRedirectUrl.searchParams.set('stripeError', e.message);
        window.location = cartRedirectUrl.href;
    }

    __construct(props) {
        super.__construct(props);
        this.getPaymentsConfig();
    }

    getPaymentsConfig = async (isFirstTime = true) => {
        const { showErrorNotification } = this.props;

        // vvv We wait for this, otherwise would need to check in didUpdate and __construct
        await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);

        const { stripePaymentsConfig: paymentsConfig } = await fetchQuery(
            StripePaymentsConfigQuery.getStripePaymentsConfigField()
        );

        const { savedMethods } = paymentsConfig;

        // vvv Allow only one more request to avoid infinite loop
        if (!savedMethods) {
            if (isFirstTime) {
                await this.getPaymentsConfig(false);
                // ^^^ Try requesting config again, for some reason this helps
                //     this is known to happen for guest users only
                return;
            }

            showErrorNotification(__('Sorry, can not initialize Stripe. Reload the page.'));
            return;
        }

        this.setState({
            paymentsConfig,
            currentDropdownValue: savedMethods.length > 0
                ? savedMethods[0].fingerprint
                : STRIPE_NEW_PAYMENT_METHOD
        }, () => {
            // vvv Init payment form, config is available now
            if (this.getIsPaymentFormVisible()) {
                this.initPaymentForm();
            }
        });
    };

    setCurrentDropdownValue(currentDropdownValue) {
        this.setState({ currentDropdownValue });
    }

    containerProps() {
        const { currentDropdownValue } = this.state;

        return {
            isLoading: false,
            // isLoading: this.getIsLoading(), // Descomentamos por si de fallo el pago no se quede cargando isLoading infinitamente
            isPaymentFormVisible: this.getIsPaymentFormVisible(),
            isCvcCollectionNeeded: this.getIsCvcCollectionNeeded(),
            currentDropdownValue,
            dropdownOptions: this.getDropdownOptions()
        };
    }

    getIsCvcCollectionNeeded() {
        const {
            currentDropdownValue,
            paymentsConfig: { savedMethods }
        } = this.state;

        return savedMethods.some((savedMethod) => {
            const { fingerprint, type, cvc } = savedMethod;

            if (fingerprint !== currentDropdownValue) {
                // ^^^ This method is not selected
                return false;
            }

            if (type !== 'card') {
                return false;
            }

            // vvv Only return if there is no CVC on card
            return !cvc;
        });
    }

    getIsPaymentFormVisible() {
        const { currentDropdownValue } = this.state;
        return currentDropdownValue === STRIPE_NEW_PAYMENT_METHOD;
    }

    getIsLoading() {
        const {
            isCurrentlyLoading,
            paymentsConfig: { clientSecret }
        } = this.state;

        return isCurrentlyLoading || clientSecret === undefined;
    }

    getDropdownOptions() {
        const { paymentsConfig: { savedMethods } } = this.state;

        return [
            ...savedMethods.map((savedMethod) => {
                const {
                    fingerprint,
                    label,
                    icon,
                    brand
                } = savedMethod;

                return {
                    label,
                    icon,
                    brand,
                    value: fingerprint,
                    id: fingerprint
                };
            }),
            {
                label: __('New payment method'),
                value: STRIPE_NEW_PAYMENT_METHOD,
                id: STRIPE_NEW_PAYMENT_METHOD
            }
        ];
    }

    render() {
        return (
            <StripePayments
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StripePaymentsContainer);
