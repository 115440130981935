/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * DisponibilidadTiendas Mutations
 * @class DisponibilidadTiendas
 * @namespace Elganso/Query/DisponibilidadTiendas/Query */
export class DisponibilidadTiendasQuery {
    getDisponibilidadProductoMutation(options) {
        return new Field('consultarDisponibilidad')
            .addArgument('input', 'DisponibilidadInput!', options)
            .addFieldList(this._getDisponibilidadProductoFields());
    }

    _getDisponibilidadProductoFields() {
        return [
            'result'
        ];
    }
}

export default new DisponibilidadTiendasQuery();
