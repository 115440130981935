import { PureComponent } from 'react';

import getStore from 'Util/Store';

import YBChatbot from './YBChatbot.component';

/** @namespace Elganso/Component/YBChatbot/Container */
export class YBChatbotContainer extends PureComponent {
    state = {
        openedChatbot: false,
        showChatbot: false,
        urlChatbot: null
    };

    containerFunctions = {
        onClickButtonChat: this.onClickButtonChat.bind(this)
    };

    componentDidMount() {
        this.checkShowChatbot();
    }

    containerProps() {
        const { openedChatbot, showChatbot, urlChatbot } = this.state;

        return {
            openedChatbot,
            showChatbot,
            urlChatbot
        };
    }

    checkShowChatbot() {
        const {
            ConfigReducer: {
                chatbot: {
                    activo = false,
                    url = null
                } = {}
            }
        } = getStore().getState();

        this.setState({ showChatbot: activo, urlChatbot: url });
    }

    onClickButtonChat() {
        const { openedChatbot } = this.state;

        this.setState({ openedChatbot: !openedChatbot });
    }

    render() {
        return (
            <YBChatbot
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default YBChatbotContainer;
