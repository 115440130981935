// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
import TagManager from 'react-gtm-module';

const googleTagRemoveProductCartEvent = (args, callback, instance) => {
    const localStorageConfig = JSON.parse(window.localStorage.config);
    const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
    const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;

    if (gtm) {
        const { item: currentItem } = instance.props;
        const currentProduct = currentItem.product;
        // eslint-disable-next-line fp/no-let
        let categories = '';

        if (currentProduct?.categories) {
            // eslint-disable-next-line no-unused-vars
            categories = currentProduct.categories.map((cat, index) => cat.name).join(',');
        }

        const tagManagerArgs = {
            gtmId: gtmID,
            dataLayer: {
                event: 'removeFromCart',
                ecommerce: {
                    remove: {
                        products: {
                            id: currentProduct.sku,
                            name: currentProduct.name,
                            price: '',
                            category: categories
                        }
                    }
                }
            },
            dataLayerName: 'YeboyeboDataLayer'
        };

        TagManager.dataLayer(tagManagerArgs);
    }

    return callback(...args);
};

export default {
    'Component/CartItem/Container': {
        'member-function': {
            removeProductAndUpdateCrossSell: googleTagRemoveProductCartEvent
        }
    }
};
