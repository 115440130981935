/**
 * YEBOYEBO Hreflang compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import history from 'Util/History';

const YeboyeboAddHrefLang = (args, callback) => {
    const { location } = history;
    setTimeout(() => {
        if (window.localStorage?.config) {
            const localStorageConfig = JSON.parse(window.localStorage.config);
            const { state, pathname } = location;
            // eslint-disable-next-line no-mixed-operators
            if (state && (state.category || state.product || state.popupOpen) || pathname.includes('html')) {
                // console.log("no metemos hreflang");
            } else if (localStorageConfig.data?.hrefLangConfig) {
                const hrefLangBase = JSON.parse(localStorageConfig.data.hrefLangConfig.hrefLangBase);
                // eslint-disable-next-line array-callback-return
                Object.entries(hrefLangBase).map(([index, item]) => {
                    // eslint-disable-next-line prefer-const
                    let link = document.createElement('link');
                    link.rel = 'alternate';
                    link.hreflang = index;
                    link.href = item;
                    document.head.appendChild(link);
                    if (index === 'x-default') {
                        link.href = item.replace(pathname, '');
                        if (pathname.split('/')[1]) {
                            link.href = item.replace(`${pathname.split('/')[1] }/`, '');
                        }
                    }
                    if (item.includes(pathname) && index !== 'x-default') {
                        // eslint-disable-next-line prefer-const
                        let canonical = document.createElement('link');
                        canonical.rel = 'canonical';
                        canonical.href = item;
                        document.head.appendChild(canonical);
                    }
                });
            }
        }
    }, 200);

    return callback(...args);
};

export default {
    'Component/Router/Container': {
        'member-function': {
            componentDidMount: YeboyeboAddHrefLang
        }
    }
};
