import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common.type';

import VideoVimeo from './VideoVimeo.component';

/** @namespace Elganso/Component/VideoVimeo/Container */
export class VideoVimeoContainer extends PureComponent {
    static propTypes = {
        src: PropTypes.string.isRequired,
        src_mobile: PropTypes.string.isRequired,
        isAutoplay: PropTypes.bool.isRequired,
        isMuted: PropTypes.bool.isRequired,
        isLoop: PropTypes.bool.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    containerProps() {
        const {
            src,
            src_mobile,
            isAutoplay,
            isMuted,
            isLoop,
            mix
        } = this.props;

        return {
            src,
            src_mobile,
            isAutoplay,
            isMuted,
            isLoop,
            mix
        };
    }

    render() {
        return (
            <VideoVimeo
              { ...this.containerProps() }
            />
        );
    }
}

export default VideoVimeoContainer;
