/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import TagManager from 'react-gtm-module';

const gogleTagManagerCheckoutData = (args, callback, instance) => {
    const localStorageConfig = JSON.parse(window.localStorage.config);
    const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
    const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;

    if (gtm) {
        const { checkoutTotals } = instance.props;

        if (checkoutTotals.items) {
            const category = '';
            // eslint-disable-next-line arrow-body-style
            const products = checkoutTotals.items.map((item, index) => {
                return {
                    id: item.product.sku,
                    name: item.product.name,
                    price: item.prices.price.value,
                    // eslint-disable-next-line object-shorthand
                    category: category,
                    qty: item.quantity,
                    position: index
                };
            });

            const tagManagerArgs = {
                gtmId: gtmID,
                dataLayer: {
                    ecommerce: {
                        checkout: {
                            // eslint-disable-next-line object-shorthand
                            products: products,
                            actionField: {
                                step: 2,
                                option: 'Checkout'
                            }
                        }
                    }
                },
                dataLayerName: 'YeboyeboDataLayer'
            };

            TagManager.dataLayer(tagManagerArgs);
        }
    }

    return callback(...args);
};

export default {
    'Route/Checkout/Component': {
        'member-function': {
            updateStepURL: gogleTagManagerCheckoutData
        }
    }
};
