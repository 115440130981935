import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

/** @namespace Elganso/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...this.propTypes,
        value: PropTypes.number
    };
}

export default FieldContainer;
