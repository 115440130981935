// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import hrefLangConfigQuery from '../../query/hrefLangConfig.query';

const addhrefLangConfigToRequest = (args, callback) => ([
    ...callback(...args),
    hrefLangConfigQuery.getQuery()
]);

const addhrefLangConfigToState = (args, callback) => ({
    ...callback(...args),
    hrefLangConfig: {}
});

const gethrefLangConfigFromAction = (args, callback) => {
    const [, action] = args;
    const {
        type,
        config: {
            hrefLangConfig = {}
        } = {}
    } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        hrefLangConfig
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addhrefLangConfigToRequest
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addhrefLangConfigToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: gethrefLangConfigFromAction
    }
};
