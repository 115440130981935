/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import { PaypalSmartButtonStyleConfigType } from '../../type/Paypal.type';

import './PaypalSmartButtons.style';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalSmartButtons/Component */
export class PaypalSmartButtonsComponent extends PureComponent {
    static propTypes = {
        page: PropTypes.string.isRequired,
        handleOnClick: PropTypes.func.isRequired,
        handleOnError: PropTypes.func.isRequired,
        handleOnApprove: PropTypes.func.isRequired,
        handleOnCancel: PropTypes.func.isRequired,
        handleCreateOrder: PropTypes.func.isRequired,
        paypal: PropTypes.shape({
            Buttons: PropTypes.func
        }),
        style: PaypalSmartButtonStyleConfigType.isRequired
    };

    static defaultProps = {
        paypal: null
    };

    renderButtons() {
        const {
            paypal,
            style,
            handleOnClick,
            handleOnError,
            handleOnApprove,
            handleOnCancel,
            handleCreateOrder
        } = this.props;

        if (!paypal || !paypal.Buttons) {
            return null;
        }

        const PayPalButtons = paypal.Buttons?.driver('react', { React, ReactDOM });

        return (
          <PayPalButtons
            onClick={ handleOnClick }
            createOrder={ handleCreateOrder }
            onApprove={ handleOnApprove }
            onCancel={ handleOnCancel }
            onError={ handleOnError }
            style={ style }
          />
        );
    }

    render() {
        const { page } = this.props;

        return (
          <div
            id={ `${page}-buttons` }
            block="PaypalSmartButtons"
          >
            { this.renderButtons() }
          </div>
        );
    }
}

export default PaypalSmartButtonsComponent;
