/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';

import './YeboyeboProducts.style';

/** @namespace YeboyeboProducts/Component/YeboyeboProducts/Component */
export class YeboyeboProductsComponent extends PureComponent {
    static propTypes = {
        ...this.propTypes
    };

    renderDescription() {
        const { composicion_textil, lavado } = this.props;

        if (!composicion_textil && !lavado) {
            return (
                <TextPlaceholder length="long" />
            );
        }

        // eslint-disable-next-line fp/no-let
        let htmlComposicion = '';
        // eslint-disable-next-line fp/no-let
        let htmlLavado = '';

        if (composicion_textil?.attribute_value) {
            const cleanComposicion = composicion_textil.attribute_value.replace(/<\/?[^>]+(>|$)/g, '');
            htmlComposicion = `${__('Composicion: ') + cleanComposicion }<br />`;
        }

        if (lavado?.attribute_value) {
            const cleanLavado = lavado.attribute_value.replace(/<\/?[^>]+(>|$)/g, '');
            htmlLavado = `${__('Lavado: ') + cleanLavado }`;
        }

        const html = `${ htmlComposicion }${ htmlLavado }`;

        return (
            <div block="YeboyeboProducts" elem="Composicion">
                <meta itemProp="composicion" content={ html } />
                <Html content={ html } />
            </div>
        );
    }

    renderContent() {
        const heading = __('Composición y Lavado');

        return (
            <ExpandableContent
              // show placeholder if the details are not loaded
              heading={ heading }
              mix={ { block: 'YeboyeboProducts', elem: 'Content' } }
            >
                { this.renderDescription() }
            </ExpandableContent>
        );
    }

    render() {
        return (
            <ContentWrapper
              label="Composicion y lavado"
              mix={ { block: 'YeboyeboProducts' } }
              wrapperMix={ { block: 'YeboyeboProducts', elem: 'Wrapper' } }
            >
                { this.renderContent() }
            </ContentWrapper>
        );
    }
}

export default YeboyeboProductsComponent;
