/* eslint-disable no-unused-expressions */
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { validateEmail } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './YBFormWebForm.style';

/** @namespace Elganso/Component/YBFormWebForm/Component */
export class YBFormWebForm extends PureComponent {
    handleSubmit = this.handleSubmit.bind(this);

    handleOnKeyUp = this.handleOnKeyUp.bind(this);

    handleNextPreviousFieldset = this.handleNextPreviousFieldset.bind(this);

    handleNextPreviousFieldset(e) {
        const {
            nextPreviousFieldset
        } = this.props;

        nextPreviousFieldset(e);
    }

    handleSubmit(e) {
        const { submitForm } = this.props;
        submitForm(e);
    }

    handleOnKeyUp(e) {
        const { onKeyUpFunction } = this.props;
        onKeyUpFunction(e);
    }

    renderFields(fieldsets) {
        const { activeFieldset } = this.props;
        if (fieldsets[0] && fieldsets[0].fields.length > 0) {
            return (
                fieldsets.map((fieldset, index) => {
                    const { fields } = fieldset;
                    return (
                        <div
                          id={ `fieldset_index_${ index + 1 }` }
                          className={ (parseInt(index, 10) + 1 === parseInt(activeFieldset, 10)) ? 'active' : 'no-active' }
                          key={ fieldset.fieldset_id }
                        >
                            { fields.map((field) => this.renderField(field)) }
                            <div className="wrapper-buttons">
                                { this.renderButtons(index, fieldsets) }
                            </div>
                        </div>
                    );
                })
            );
        }

        return null;
    }

    renderButtons(index, fieldsets) {
        const {
            webformsForm: {
                submit_button_text
            }
        } = this.props;

        const fieldsets_length = fieldsets.length;

        if (fieldsets_length > 1) {
            if (index === 0) {
                return (
                    <button block="fieldset-next" type={ FIELD_TYPE.button } data-index-asociado={ index + 2 } onClick={ this.handleNextPreviousFieldset }>
                        { __('SIGUIENTE') }
                    </button>
                );
            }

            if ((index + 1) === fieldsets_length) {
                return (
                    <>
                        <button block="fieldset-back" type={ FIELD_TYPE.button } data-index-asociado={ index } onClick={ this.handleNextPreviousFieldset }>
                            { __('ATRAS') }
                        </button>
                        <button type="submit" block="webform-submit">
                            { submit_button_text }
                        </button>
                    </>
                );
            }

            return (
                <>
                    <button block="fieldset-back" type={ FIELD_TYPE.button } data-index-asociado={ index } onClick={ this.handleNextPreviousFieldset }>
                        { __('ATRAS') }
                    </button>
                    <button block="fieldset-next" type={ FIELD_TYPE.button } data-index-asociado={ index + 2 } onClick={ this.handleNextPreviousFieldset }>
                        { __('SIGUIENTE') }
                    </button>
                </>
            );
        }

        return (
            <button type="submit" block="webform-submit">
                { submit_button_text }
            </button>
        );
    }

    renderField(field) {
        if (field.type === 'email') {
            return this.renderFieldEmail(field);
        }
        if (field.type === 'select_checkbox') {
            return this.renderFieldCheckbox(field);
        }
        if (field.type === 'text') {
            return this.renderFieldText(field);
        }
        if (field.type === 'textarea') {
            return this.renderFieldTextArea(field);
        }
        if (field.type === 'select_radio') {
            return this.renderFieldSelectRadio(field);
        }

        return this.renderInput(field);
    }

    getStilosFormat(estilo) {
        const regex = /([\w-]*)\s*:\s*([^;]*)/g;
        const properties = {};
        let match = {};

        // eslint-disable-next-line no-cond-assign
        while (match = regex.exec(estilo)) {
            properties[match[1].replace(/-./g, (x) => x[1].toUpperCase())] = match[2].trim();
        }

        return properties;
    }

    renderFieldEmail(field) {
        const {
            is_active,
            is_required,
            name,
            css_input_style,
            css_container_class,
            is_label_hidden
        } = field;

        if (is_active) {
            return (
                <Field
                  key={ field.field_id }
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: field.field_id,
                      name: field.field_id,
                      placeholder: name,
                      required: is_required,
                      className: `input-text ${ field.css_input_class }`,
                      style: this.getStilosFormat(css_input_style)
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: is_required,
                      inputType: VALIDATION_INPUT_TYPE.email,
                      match: (value) => validateEmail(value)
                  } }
                  addRequiredTag={ is_required }
                  value={ 0 }
                  events={ { onKeyUp: this.handleOnKeyUp } }
                  label={ (is_label_hidden) ? '' : name }
                  mix={ { className: css_container_class } }
                />
            );
        }

        return null;
    }

    getValueByCode(code) {
        const { parametrosEncuestas = {} } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get(code)) {
            return urlParams.get(code);
        }

        if (parametrosEncuestas) {
            if (parametrosEncuestas[code]) {
                return parametrosEncuestas[code];
            }

            return '';
        }

        return '';
    }

    renderFieldText(field) {
        const {
            is_active,
            is_required,
            name,
            code,
            css_input_style,
            css_container_class,
            is_label_hidden
        } = field;

        const {
            parametrosEncuestas
        } = this.props;

        const {
            webformsForm: {
                is_url_parameters_accepted
            }
        } = this.props;

        let valueField = '';

        if (is_url_parameters_accepted) {
            valueField = this.getValueByCode(code);
        }

        if (is_active) {
            if (parametrosEncuestas) {
                return (
                    <Field
                      key={ field.field_id }
                      type={ FIELD_TYPE.text }
                      attr={ {
                          id: field.field_id,
                          name: field.field_id,
                          placeholder: name,
                          required: is_required,
                          className: `input-text ${ field.css_input_class }`,
                          style: this.getStilosFormat(css_input_style),
                          value: parametrosEncuestas[name]
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: is_required,
                          inputType: VALIDATION_INPUT_TYPE.text
                      } }
                      addRequiredTag={ is_required }
                      value={ 0 }
                      events={ { onKeyUp: this.handleOnKeyUp } }
                      label={ (is_label_hidden) ? '' : name }
                      mix={ { className: css_container_class } }
                    />
                );
            }

            if (valueField) {
                return (
                    <Field
                      key={ field.field_id }
                      type={ FIELD_TYPE.text }
                      attr={ {
                          id: field.field_id,
                          name: field.field_id,
                          placeholder: name,
                          required: is_required,
                          className: `input-text ${ field.css_input_class }`,
                          style: this.getStilosFormat(css_input_style),
                          value: valueField
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: is_required,
                          inputType: VALIDATION_INPUT_TYPE.text
                      } }
                      addRequiredTag={ is_required }
                      value={ 0 }
                      events={ { onKeyUp: this.handleOnKeyUp } }
                      label={ (is_label_hidden) ? '' : name }
                      mix={ { className: css_container_class } }
                    />
                );
            }

            return (
                <Field
                  key={ field.field_id }
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: field.field_id,
                      name: field.field_id,
                      placeholder: name,
                      required: is_required,
                      className: `input-text ${ field.css_input_class }`,
                      style: this.getStilosFormat(css_input_style)
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: is_required,
                      inputType: VALIDATION_INPUT_TYPE.text
                  } }
                  addRequiredTag={ is_required }
                  value={ 0 }
                  events={ { onKeyUp: this.handleOnKeyUp } }
                  label={ (is_label_hidden) ? '' : name }
                  mix={ { className: css_container_class } }
                />
            );
        }

        return null;
    }

    renderFieldCheckbox(field) {
        const {
            is_active,
            is_required,
            name,
            type_attributes_serialized,
            css_input_style,
            css_container_class
        } = field;

        let optionsString = JSON.parse(type_attributes_serialized);
        optionsString = optionsString.options;

        if (is_active) {
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className="webform-checkbox" key={ field.field_id } mix={ { className: css_container_class } }>
                    <Field
                      key={ field.field_id }
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: field.field_id,
                          name: field.field_id,
                          placeholder: name,
                          required: is_required,
                          className: field.css_input_class,
                          style: this.getStilosFormat(css_input_style)
                      } }
                      validationRule={ {
                          isRequired: is_required
                      } }
                      addRequiredTag={ is_required }
                      value=""
                      label={ <Html content={ optionsString } /> }
                    />
                </label>

            );
        }

        return null;
    }

    renderInput(field) {
        return (
            <p key={ field.name }>renderiazndo input</p>
        );
    }

    renderFieldTextArea(field) {
        const {
            is_active,
            is_required,
            name,
            css_input_style,
            css_container_class,
            is_label_hidden
        } = field;

        if (is_active) {
            return (
                <Field
                  key={ field.field_id }
                  type={ FIELD_TYPE.textarea }
                  attr={ {
                      id: field.field_id,
                      name: field.field_id,
                      required: is_required,
                      className: `input-text ${ field.css_input_class }`,
                      style: this.getStilosFormat(css_input_style),
                      rows: 5
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: is_required
                  } }
                  addRequiredTag={ is_required }
                  value={ 0 }
                  label={ (is_label_hidden) ? '' : name }
                  mix={ { className: css_container_class } }
                />
            );
        }

        return null;
    }

    renderFieldSelectRadio(field) {
        const {
            // browser_autocomplete,
            code,
            // comment,
            css_container_class,
            css_input_class,
            // css_input_style,
            // custom_attributes,
            // display_in_result,
            field_id,
            // fieldset_id,
            // form_id,
            is_active,
            // is_displayed_in_new_row_lg,
            // is_displayed_in_new_row_md,
            // is_displayed_in_new_row_sm,
            // is_label_hidden,
            is_required,
            // is_unique,
            // max_length,
            // max_length_validation_message,
            // min_length,
            // min_length_validation_message,
            name,
            // position,
            // regex_validation_message,
            // regex_validation_pattern,
            // result_label,
            // type,
            type_attributes_serialized,
            // unique_validation_message,
            // validation_required_message,
            width_proportion_lg,
            width_proportion_md,
            width_proportion_sm
        } = field;

        if (is_active) {
            const { options, is_internal_elements_inline } = JSON.parse(type_attributes_serialized);
            const optionSplit = options.split('\r\n');

            // PROCESAR LAS CLASES
            let classes = '';
            (css_container_class) ? classes += ` ${ css_container_class }` : '';
            (is_internal_elements_inline) ? classes += ' inline-elements' : '';
            (is_required) ? classes += ' required' : '';
            classes += ` webforms-fields-${ field_id }`;
            classes += ` webforms-fields-${ code }`;
            (width_proportion_lg) ? classes += ` wf-lg-${ width_proportion_lg }` : '';
            (width_proportion_md) ? classes += ` wf-md-${ width_proportion_md }` : '';
            (width_proportion_sm) ? classes += ` wf-sm-${ width_proportion_sm }` : '';

            return (
                <FieldGroup
                  validationRule={ { isRequired: is_required, selector: '[type="radio"]' } }
                  validateOn={ ['onChange'] }
                  key={ `select-group-${ field_id }` }
                  elem={ `FieldGroup-${ field_id }` }
                  attr={ {
                      className: classes
                  } }
                  label={ name }
                >
                    { (css_input_class)
                        ? (
                        <div className={ css_input_class }>
                            { optionSplit.map((option) => {
                                const optionProcesed = option.replace('}}', '').split(' {{val ');
                                const opcion = {
                                    text: optionProcesed[0],
                                    value: optionProcesed[1]
                                };

                                return (
                                    this.renderRadioOption(opcion, field)
                                );
                            }) }
                        </div>
                        )
                        : (
                            <>
                                { optionSplit.map((option) => {
                                    const optionProcesed = option.replace('}}', '').split(' {{val ');
                                    const opcion = {
                                        text: optionProcesed[0],
                                        value: optionProcesed[1]
                                    };

                                    return (
                                        this.renderRadioOption(opcion, field)
                                    );
                                }) }
                            </>
                        ) }
                </FieldGroup>
            );
        }

        return null;
    }

    renderRadioOption(opcion, field) {
        const {
            text,
            value
        } = opcion;

        const {
            onRadioOptionClick
        } = this.props;

        return (
            <div className={ `field choice option-${ value }` } key={ `choice option-${ field.field_id }-${ value }` }>
                <Field
                  id={ `${ field.field_id }_${ value }` }
                  name={ field.field_id }
                  attr={ {
                      id: `${ field.field_id }_${ value }`,
                      name: field.field_id,
                      value
                  } }
                  type="radio"
                  className="radio"
                  value={ parseInt(value, 10) }
                  aria-labelledby={ `label_field_${ field.field_id }_${ value }` }
                  events={ {
                      onChange: onRadioOptionClick
                  } }
                  label={ text }
                />
            </div>
        );
    }

    render() {
        const {
            webformsForm,
            webformsForm: {
                code,
                fieldsets,
                success_text,
                description
            },
            resultWebForm,
            isLoading
        } = this.props;

        if (isLoading) {
            return (
                <Loader isLoading={ isLoading } />
            );
        }

        if (webformsForm && webformsForm?.is_active) {
            if (resultWebForm) {
                if (resultWebForm.webformsSubmitForm.success) {
                    return (
                        <div block="YBFormWebForm">
                            <p className="submit_success">{ success_text }</p>
                        </div>
                    );
                }

                const error = JSON.parse(resultWebForm.webformsSubmitForm.errors)[0];
                return (
                    <div block="YBFormWebForm">
                    <Html content={ description || '' } />
                        <form name={ code } onSubmit={ this.handleSubmit }>
                            { this.renderFields(fieldsets) }
                        </form>
                        <p className="submit_error">{ error }</p>
                    </div>
                );
            }

            return (
                <div block="YBFormWebForm">
                    <Html content={ description || '' } />
                    <form name={ code } onSubmit={ this.handleSubmit }>
                        { this.renderFields(fieldsets) }
                    </form>
                </div>
            );
        }

        return null;
    }
}

export default YBFormWebForm;
