// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import popupBuilderConfigQuery from '../../query/popupBuilderConfig.query';

const addpopupBuilderConfigToRequest = (args, callback) => ([
    ...callback(...args),
    popupBuilderConfigQuery.getQuery()
]);

const addpopupBuilderConfigToState = (args, callback) => ({
    ...callback(...args),
    getPopUpListConditions: []
});

const getpopupBuilderConfigFromAction = (args, callback) => {
    const [, action] = args;
    const {
        type,
        config: {
            getPopUpListConditions = []
        } = {}
    } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        getPopUpListConditions
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addpopupBuilderConfigToRequest
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addpopupBuilderConfigToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getpopupBuilderConfigFromAction
    }
};
