import PropTypes from 'prop-types';

import {
    DiscountType as SourceDiscountType,
    OriginalPriceType as SourceOriginalPriceType,
    PriceItemType as SourcePriceItemType,
    PriceType as SourcePriceType,
    PriceVariantType as SourcePriceVariantType,
    ProductPriceType as SourceProductPriceType,
    TierPricesType as SourceTierPricesType
} from 'SourceType/Price.type';

export const PriceItemType = SourcePriceItemType;

export const DiscountType = SourceDiscountType;

export const PriceVariantType = SourcePriceVariantType;

export const PriceType = SourcePriceType;

export const OriginalPriceType = SourceOriginalPriceType;

export const ProductPriceType = PropTypes.shape({
    SourceProductPriceType,
    configuration: PropTypes.shape({
        containsOptions: PropTypes.bool,
        containsOptionsWithPrice: PropTypes.bool,
        containsRequiredOptions: PropTypes.bool,
        containsRequiredOptionsWithPrice: PropTypes.bool
    })
});

export const TierPricesType = SourceTierPricesType;
