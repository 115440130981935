/**
 * Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/googletagmanager/Query/PaypalConfigs/Query */
export class PixelConfigs {
    getQuery() {
        return new Field('facebookPixelConfigs')
            .addFieldList(this.getPixelConfigsFields());
    }

    getPixelConfigsFields() {
        return [
            'facebookPixelID',
            'isEnabled'
        ];
    }
}

export default new PixelConfigs();
