import {
    TYPE_CATEGORY as SOURCE_TYPE_CATEGORY,
    TYPE_CMS_PAGE as SOURCE_TYPE_CMS_PAGE,
    TYPE_NOTFOUND as SOURCE_TYPE_NOTFOUND,
    TYPE_PRODUCT as SOURCE_TYPE_PRODUCT
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';

export const TYPE_CMS_PAGE = SOURCE_TYPE_CMS_PAGE;
export const TYPE_CATEGORY = SOURCE_TYPE_CATEGORY;
export const TYPE_NOTFOUND = SOURCE_TYPE_NOTFOUND;
export const TYPE_PRODUCT = SOURCE_TYPE_PRODUCT;
