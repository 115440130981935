import { PureComponent } from 'react';

import WebFormsQuery from 'Query/WebForms.query';
import { prepareQuery } from 'Util/Query';
import { fetchMutation } from 'Util/Request';
import { executeGet } from 'Util/Request/Request';

import YBFormWebForm from './YBFormWebForm.component';

/** @namespace Elganso/Component/YBFormWebForm/Container */
export class YBFormWebFormContainer extends PureComponent {
    containerFunctions = {
        submitForm: this.submitForm.bind(this),
        nextPreviousFieldset: this.nextPreviousFieldset.bind(this),
        onKeyUpFunction: this.onKeyUpFunction.bind(this),
        onRadioOptionClick: this.onRadioOptionClick.bind(this)
    };

    state = {
        webformsForm: '',
        resultWebForm: null,
        activeFieldset: 1,
        optionData: {},
        isLoading: false
    };

    componentDidMount() {
        this._getForm();
    }

    containerProps() {
        const { form_id, parametrosEncuestas } = this.props;
        const {
            webformsForm, resultWebForm, activeFieldset, isLoading
        } = this.state;

        return {
            form_id,
            webformsForm,
            resultWebForm,
            activeFieldset,
            parametrosEncuestas,
            isLoading
        };
    }

    onRadioOptionClick(e) {
        const { name, value } = e.target;

        // Buscamos el contenedor del elemento clickeado
        const fieldWrapper = document.querySelectorAll(`.webforms-fields-${ name }`);

        // Seleccionamos las opciones clickeadas antes y elmeinamos la clase clickeada
        const opciones = fieldWrapper[0].querySelectorAll('.Field-RadioLabel_isChecked');
        opciones.forEach((opcion) => {
            opcion.classList.remove('Field-RadioLabel_isChecked');
        });

        // Añadimos la clase a la opcion clickeada
        e.currentTarget.parentNode.classList.add('Field-RadioLabel_isChecked');

        // Guardamos en el estado el valor de elemento clickeado
        this.setState(({ optionData }) => ({
            optionData: { ...optionData, [name]: value }
        }));
    }

    nextPreviousFieldset(e) {
        e.preventDefault();

        this.setState({ activeFieldset: e.target.getAttribute('data-index-asociado') });
    }

    _getForm() {
        const { form_id } = this.props;

        this.setState({ isLoading: true });

        executeGet(prepareQuery(WebFormsQuery.getWebFormQuery(form_id)), 'getWebForm', 15552000).then(
        /** @namespace Elganso/Component/YBFormWebForm/Container/YBFormWebFormContainer/_getForm/executeGet/then */
            (result) => {
                const { webformsForm } = result;
                this.setState({ webformsForm, isLoading: false });
            }
        );
    }

    submitForm(e) {
        // Evita el reload al hacer submit
        e.preventDefault();

        const {
            form_id
        } = this.props;

        const {
            optionData
        } = this.state;

        const formElements = e.target.elements;

        const jsonData = { field: {}, submitted_from: {} };

        Array.from(formElements).filter((input) => input.type !== 'submit')
            .filter((input) => input.type !== 'button').forEach((input) => {
                jsonData.field[input.name] = input.value;
                // Si es del tipo racio comprobamos que tenga marcada la opcion en el state
                if (input.type === 'radio') {
                    jsonData.field[input.name] = null;
                    if (optionData[input.name] !== undefined) {
                        jsonData.field[input.name] = optionData[input.name];
                    }
                }
            });

        const jsonSubmittedFrom = {};

        jsonSubmittedFrom.url = window.location.href;
        jsonSubmittedFrom.title = document.title;

        jsonData.submitted_from = JSON.stringify(jsonSubmittedFrom);

        this.executeSubmitForm(form_id, JSON.stringify(jsonData));
    }

    async executeSubmitForm(formId, formData) {
        const query = WebFormsQuery.getWebformsSubmitForm(formId, formData);

        try {
            const result = await fetchMutation(query);
            this.setState({ resultWebForm: result });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }

    onKeyUpFunction(e) {
        if (e.target.value.search(' ') >= 0) {
            // eslint-disable-next-line no-param-reassign
            e.target.value = e.target.value.replace(' ', '');
        }
    }

    render() {
        return (
            <YBFormWebForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default YBFormWebFormContainer;
