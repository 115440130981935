import Vimeo from '@u-wave/react-vimeo';
import { PureComponent } from 'react';

import isMobile from 'Util/Mobile/isMobile';

import './VideoVimeo.style';

/** @namespace Elganso/Component/VideoVimeo/Component */
export class VideoVimeo extends PureComponent {
    renderVideo() {
        const {
            src,
            src_mobile,
            isAutoplay,
            isMuted,
            isLoop
        } = this.props;

        if (src && !isMobile.any()) {
            return (
                <Vimeo
                  video={ src }
                  autoplay={ isAutoplay }
                  paused={ !isAutoplay }
                  muted={ isMuted }
                  loop={ isLoop }
                  responsive
                />
            );
        }

        if (src_mobile && isMobile.any()) {
            return (
                <Vimeo
                  video={ src_mobile }
                  autoplay={ isAutoplay }
                  responsive
                  muted
                  loop
                  paused={ !isAutoplay }
                />
            );
        }

        return null;
    }

    render() {
        const { mix } = this.props;

        return (
            <div block="VideoVimeo" mix={ mix }>
                { this.renderVideo() }
            </div>
        );
    }
}

export default VideoVimeo;
