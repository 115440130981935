/* eslint-disable react/no-danger */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';
import ContentWrapper from 'Component/ContentWrapper';
import TextPlaceholder from 'Component/TextPlaceholder';

import './YeboyeboEnvioYDevoluciones.style';

/** @namespace YeboyeboProducts/Component/YeboyeboEnvioYDevoluciones/Component */
export class YeboyeboEnvioYDevolucionesComponent extends PureComponent {
    static propTypes = {
        ...this.propTypes,
        showPopup: PropTypes.func.isRequired
    };

    handleShowPopup = this.handleShowPopup.bind(this);

    handleShowPopup(e) {
        // eslint-disable-next-line react/prop-types
        const { showPopup } = this.props;
        e.preventDefault();
        showPopup();
    }

    renderButtonIcon() {
        return <AddIcon />;
    }

    renderEnvioDevoluciones() {
        return (
            <>
            <div
              role="button"
              tabIndex={ 0 }
              block="YeboyeboEnvioYDevoluciones-Envios ExpandableContent"
              onClick={ this.handleShowPopup }
              onKeyDown={ this.handleShowPopup }
            >
                <div
                  block="YeboyeboEnvioYDevoluciones-Envios ExpandableContent"
                  elem="Heading"
                >
                    { this.renderButtonIcon() }
                    <TextPlaceholder content="ENVIOS Y DEVOLUCIONES" length="medium" />
                </div>
            </div>
            { /* <Popup
              id="envios_y_devoluciones"
              clickOutside={ false }
              mix={ { block: 'YeboyeboEnvioYDevoluciones', elem: 'Popup' } }
            >
                <CmsBlock identifier={ product_delivery } />
            </Popup> */ }
            </>
        );
    }

    render() {
        return (
            <ContentWrapper
              label="Envios y devoluciones"
              mix={ { block: 'YeboyeboEnvioYDevoluciones' } }
              wrapperMix={ { block: 'YeboyeboEnvioYDevoluciones', elem: 'Wrapper' } }
            >
                { this.renderEnvioDevoluciones() }
            </ContentWrapper>
        );
    }
}

export default YeboyeboEnvioYDevolucionesComponent;
