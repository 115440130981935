import { showPopup } from 'Store/Popup/Popup.action';
import getStore from 'Util/Store';

import YeboyeboEnvioYDevoluciones from '../component/YeboyeboEnvioYDevoluciones/YeboyeboEnvioYDevoluciones.component';
import YeboyeboProducts from '../component/YeboyeboProducts/YeboyeboProducts.component';

export const PRODUCT_COMPOSICION_LAVADO_TAB = 'PRODUCT_COMPOSICION_LAVADO_TAB';
export const ENVIOS_Y_DEVOLUCIONES_TAB = 'ENVIOS_Y_DEVOLUCIONES_TAB';

function showPopupEnvios() {
    const store = getStore();
    const { dispatch } = store;
    dispatch(showPopup('envios_y_devoluciones', { title: __('ENVIOS Y DEVOLUCIONES') }));
}

export const tabMap = (member, instance) => ({
    ...member,
    [PRODUCT_COMPOSICION_LAVADO_TAB]: {
        ...instance,
        name: __('Composición y Lavado'),
        shouldTabRender: () => {
            const { isComposicionLavadoTabEmpty } = instance.props;
            return !isComposicionLavadoTabEmpty;
        },
        render: (key) => {
            const { activeProduct: { attributes } = {} } = instance.props;
            // eslint-disable-next-line max-len
            return (
                    <YeboyeboProducts
                      key={ key }
                      composicion_textil={ attributes?.composicion_textil }
                      lavado={ attributes?.lavado }
                    />
            );
        }
    },
    // Falta detectar si el bloque CMS existe, esta activo y tiene contendo para renderizar
    [ENVIOS_Y_DEVOLUCIONES_TAB]: {
        ...instance,
        name: __('Envios Y Devoluciones'),
        shouldTabRender: () => true,
        render: (key) => (
            <YeboyeboEnvioYDevoluciones
              key={ key }
              showPopup={ showPopupEnvios }
            />
        )
    }
});

export default {
    'Component/YBProductPage/Component': {
        'member-property': { tabMap }
    }
};
