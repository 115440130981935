import {
    ACCOUNT_CONFIRMATION_URL as SOURCE_ACCOUNT_CONFIRMATION_URL,
    ACCOUNT_FORGOT_PASSWORD_URL as SOURCE_ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_INFORMATION_EDIT_URL as SOURCE_ACCOUNT_INFORMATION_EDIT_URL,
    ACCOUNT_LOGIN_URL as SOURCE_ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_HISTORY as SOURCE_ACCOUNT_ORDER_HISTORY,
    ACCOUNT_ORDER_PRINT_URL as SOURCE_ACCOUNT_ORDER_PRINT_URL,
    ACCOUNT_ORDER_URL as SOURCE_ACCOUNT_ORDER_URL,
    ACCOUNT_REGISTRATION_URL as SOURCE_ACCOUNT_REGISTRATION_URL,
    ACCOUNT_URL as SOURCE_ACCOUNT_URL,
    LOCKED_ACCOUNT_ERROR_MESSAGE as SOURCE_LOCKED_ACCOUNT_ERROR_MESSAGE
} from 'SourceRoute/MyAccount/MyAccount.config';

export const ACCOUNT_MENU_URL = '/customer/menu';
export const ACCOUNT_INFORMATION_EDIT_URL = SOURCE_ACCOUNT_INFORMATION_EDIT_URL;
export const ACCOUNT_FORGOT_PASSWORD_URL = SOURCE_ACCOUNT_FORGOT_PASSWORD_URL;
export const ACCOUNT_CONFIRMATION_URL = SOURCE_ACCOUNT_CONFIRMATION_URL;
export const ACCOUNT_REGISTRATION_URL = SOURCE_ACCOUNT_REGISTRATION_URL;
export const ACCOUNT_LOGIN_URL = SOURCE_ACCOUNT_LOGIN_URL;
export const ACCOUNT_URL = SOURCE_ACCOUNT_URL;
export const ACCOUNT_ORDER_URL = SOURCE_ACCOUNT_ORDER_URL;
export const ACCOUNT_ORDER_PRINT_URL = SOURCE_ACCOUNT_ORDER_PRINT_URL;
export const ACCOUNT_ORDER_HISTORY = SOURCE_ACCOUNT_ORDER_HISTORY;
export const LOCKED_ACCOUNT_ERROR_MESSAGE = SOURCE_LOCKED_ACCOUNT_ERROR_MESSAGE;

export const MY_ACCOUNT_MENU = 'my-account-menu';
export const CONTACT = 'my-contact';
export const FAQ = 'frequently-questions';
export const SETTINGS = 'settings';
export const NOTIFICATIONS = 'notifications';
export const GANSOCIETY = 'gansociety';
export const RMA = 'rma';
export const MY_GIFT_CARDS = 'my-gift-cards';
