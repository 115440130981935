import { lazy } from 'react';

import Loader from 'Component/Loader';
import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import './UrlRewrites.override.style';

export const YBProductListPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "YBProductListPage" */ 'Route/YBProductListPage'));

export {
    ProductPage,
    CategoryPage,
    CmsPage,
    NoMatch
};

/** @namespace Elganso/Route/UrlRewrites/Component */
export class UrlRewrites extends SourceUrlRewrites {
    renderProductPage() {
        const {
            addtitionalData,
            props: {
                history,
                location,
                match,
                productSKU,
                id
            } = {}
        } = this.props;

        if (!productSKU) {
            return this.renderDefaultPage();
        }

        if (addtitionalData !== undefined && addtitionalData?.use_product_list) {
            const prevCategoryId = location.state ? location.state.prevCategoryId : null;

            return (
                <YBProductListPage
                  history={ history }
                  location={ location }
                  match={ match }
                  productSKU={ productSKU }
                  productID={ id }
                  key={ id }
                  categoryID={ prevCategoryId }
                  additionalData={ addtitionalData }
                />
            );
        }

        return (
            <ProductPage
              history={ history }
              location={ location }
              match={ match }
              productSKU={ productSKU }
              productID={ id }
              key={ id }
            />
        );
    }

    renderDefaultPage() {
        return (
            <Loader isLoading />
        );
    }
}

export default UrlRewrites;
