import {
    calculateFinalPrice as SourceCalculateFinalPrice,
    calculateTierDiscountOverSpecialPrice as SourceCalculateTierDiscountOverSpecialPrice,
    formatCurrency as SourceFormatCurrency,
    formatPrice as SourceFormatPrice,
    getLowestPriceTiersPrice as SourceGetLowestPriceTiersPrice,
    roundPrice as SourceRoundPrice
} from 'SourceUtil/Price/Price';
import getStore from 'Util/Store';

export const formatCurrency = SourceFormatCurrency;

export const formatPrice = SourceFormatPrice;

export const calculateFinalPrice = SourceCalculateFinalPrice;

export const calculateTierDiscountOverSpecialPrice = SourceCalculateTierDiscountOverSpecialPrice;

export const roundPrice = SourceRoundPrice;

export const getLowestPriceTiersPrice = SourceGetLowestPriceTiersPrice;

/** @namespace Elganso/Util/Price/roundDiscount */
export const roundDiscount = (discount) => {
    const {
        ConfigReducer: {
            redondeoRebajas = 5
        }
    } = getStore().getState();

    let round = 0;

    if (redondeoRebajas !== 5) {
        round = Math.round(discount);
    } else {
        round = Math.round((Math.round((discount / 100) * 20) / 20) * 100);
    }

    return round;
};
