import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Elganso/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Elganso/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Elganso/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    containerProps() {
        const {
            description,
            keywords
        } = this.props;

        return {
            ...super.containerProps(),
            description,
            keywords
        };
    }

    _getDescription() {
        const { description, default_description, title } = this.props;

        return description || title || default_description;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
