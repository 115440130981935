/* eslint-disable no-magic-numbers */
/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
import ReactPixel from 'react-facebook-pixel';

function stringToHash(string) {
    let hash = 0;

    if (string.length == 0) {
        return hash;
    }

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return String(hash);
}

function generateEventId(eName) {
    const uCookie = document.cookie;
    const uHash = stringToHash(uCookie);
    const url = window.location.href;
    const urlHash = stringToHash(url);

    function getTimeStamp() {
        if (!Date.now) {
            Date.now = () => {
                new Date().getTime();
            };
        }

        return Date.now();
    }

    const timestamp = String(getTimeStamp());

    return eName + uHash + urlHash + timestamp;
}

const facebookPixelAddToCartEventProduct = (args, callback, instance) => {
    const { fbq } = window;

    if (fbq && window.localStorage.config !== undefined) {
        const localStorageConfig = JSON.parse(window.localStorage.config);
        const { product: currentProduct } = instance.props;
        const currencyCode = localStorageConfig.data.currency.base_currency_code;
        const pixelArgs = {
            currency: currencyCode,
            value: currentProduct.price_range.minimum_price.final_price.value,
            content_type: 'product',
            content_ids: [currentProduct.sku],
            contents: [
                {
                    id: currentProduct.sku,
                    quantity: 1,
                    item_price: currentProduct.price_range.minimum_price.final_price.value,
                    currency: currencyCode
                }
            ]
        };

        ReactPixel.fbq('track', 'AddToCart', pixelArgs, { eventID: generateEventId('AddToCart') });
    }

    return callback(...args);
};

export default {
    'Component/Product/Container': {
        'member-function': {
            addToCart: facebookPixelAddToCartEventProduct
        }
    }
};
