/**
 * Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/googletagmanager/Query/PaypalConfigs/Query */
export class GoogleConfigsQuery {
    getQuery() {
        return new Field('googleConfigs')
            .addFieldList(this.getGoogleConfigsFields());
    }

    getGoogleConfigsFields() {
        return [
            'analitysID',
            'containerID',
            'isEnabled'
        ];
    }
}

export default new GoogleConfigsQuery();
