import {
    getNumberOfCharacterClasses as sourceGetNumberOfCharacterClasses,
    validate as sourceValidate,
    validateGroup as sourceValidateGroup,
    validatePassword as sourceValidatePassword
} from 'SourceUtil/Validator/Validator';

export const validate = sourceValidate;

export const validateGroup = sourceValidateGroup;

export const getNumberOfCharacterClasses = sourceGetNumberOfCharacterClasses;

export default validate;

export const validatePassword = sourceValidatePassword;

/**
 * Validates email
 * @param {String} value
 * @returns {String|Boolean}
 * @namespace Elganso/Util/Validator/validateEmail */
export const validateEmail = (value) => {
    let val = value;

    if (val.length === 0) {
        return true;
    }

    if (/^[A-Z0-9\\'\\+\\&\\%_-]+(\.{0,1}[A-Z0-9\\'\\+\\&\\%_-]+)*[@]{1}[A-Z0-9.-]*[A-Z0-9-]+[.]{1}[A-Z]{2,4}$/i.test(val)) {
        val = val.toLowerCase();

        if (val.includes('@gmail')) {
            if (!/^[A-Z0-9\\'\\+\\&\\%_-]+(\.{0,1}[A-Z0-9\\'\\+\\&\\%_-]+)*@gmail\.com$/i.test(val)) {
                return __('gmail should always end with .com');
            }
        }

        if (val.includes('@outlook')) {
            if (!/^[A-Z0-9\\'\\+\\&\\%_-]+(\.{0,1}[A-Z0-9\\'\\+\\&\\%_-]+)*@outlook\.com$/i.test(val) && !/^[A-Z0-9\\'\\+\\&\\%_-]+(\.{0,1}[A-Z0-9\\'\\+\\&\\%_-]+)*@outlook\.es$/i.test(val)) {
                return __('outlook should always ends with .com or .es');
            }
        }

        return true;
    }

    return __('Invalid email address');
};
