import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace Elganso/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    // TODO implement logic
}

export default CmsBlockContainer;
