import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList.type';

import ItemListTiendaDisponibilidad from './ItemListTiendaDisponibilidad.component';

/** @namespace Elganso/Component/ItemListTiendaDisponibilidad/Container */
export class ItemListTiendaDisponibilidadContainer extends PureComponent {
    static propTypes = {
        tienda: PropTypes.objectOf.isRequired,
        product: ProductType.isRequired,
        onClickVisitMapStore: PropTypes.func.isRequired
    };

    state = {
        itemOpened: false
    };

    containerFunctions = {
        clicShowInfoShop: this.clicShowInfoShop.bind(this)
    };

    containerProps() {
        const { onClickVisitMapStore, product, tienda } = this.props;
        const { itemOpened } = this.state;

        return {
            itemOpened,
            onClickVisitMapStore,
            product,
            tienda
        };
    }

    clicShowInfoShop() {
        const { itemOpened } = this.state;

        this.setState({ itemOpened: !itemOpened });
    }

    render() {
        return (
            <ItemListTiendaDisponibilidad
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ItemListTiendaDisponibilidadContainer;
