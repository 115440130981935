import {
    CONFIRMATION_REQUIRED,
    SHOW_VAT_NUMBER_OPTIONAL as SOURCE_SHOW_VAT_NUMBER_OPTIONAL,
    SHOW_VAT_NUMBER_REQUIRED as SOURCE_SHOW_VAT_NUMBER_REQUIRED
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

export {
    CONFIRMATION_REQUIRED
};

// TODO: implement SHOW_VAT_NUMBER_REQUIRED
export const SHOW_VAT_NUMBER_REQUIRED = SOURCE_SHOW_VAT_NUMBER_REQUIRED;

// TODO: implement SHOW_VAT_NUMBER_OPTIONAL
export const SHOW_VAT_NUMBER_OPTIONAL = SOURCE_SHOW_VAT_NUMBER_OPTIONAL;
