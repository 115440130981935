import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';

import {
    IMAGE_LOADING
} from './Image.config';

import './Image.override.style';

/** @namespace Elganso/Component/Image/Component */
export class ImageComponent extends SourceImage {
    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title
        } = this.props;
        const { imageStatus } = this.state;

        return (
            <img
              block="Image"
              elem="Image"
              src={ src || '' }
              alt={ alt }
              mods={ { isLoading: imageStatus === IMAGE_LOADING } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
            />
        );
    }

    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className
        } = this.props;

        return (
            <img
              block={ className }
              src={ src || '' }
              alt={ alt }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
            />
        );
    }

    renderImageNotFound() {
        // Evita error en SEO cuando no encuentra la imagen y pone el Texto "Image not found" con traducciones
        return null;
    }

    renderImageNotSpecified() {
        return null;
    }
}

export default ImageComponent;
