/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PaypalBillingAgreements from '../../component/PaypalBillingAgreements';
import PaypalPayment from '../../component/PaypalPayment';
import PaypalSmartButtons from '../../component/PaypalSmartButtons';
import {
    PAYPAL_BILLING_AGREEMENT_PAYMENT_METHOD_CODE,
    PAYPAL_EXPRESS_PAYMENT_METHOD_CODE,
    PAYPAL_PAYLATER_PAYMENT_METHOD_CODE,
    PAYPAL_PLACEMENT_CHECKOUT_PAGE
} from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPaypalPayments = (member, instance) => {
    const { setOrderButtonEnableStatus, setOrderButtonVisibility } = instance.props;

    return {
        ...member,
        [PAYPAL_BILLING_AGREEMENT_PAYMENT_METHOD_CODE]: () => (
            <PaypalBillingAgreements
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
        ),
        [PAYPAL_EXPRESS_PAYMENT_METHOD_CODE]: () => (
            <PaypalSmartButtons
              setOrderButtonVisibility={ setOrderButtonVisibility }
              page={ PAYPAL_PLACEMENT_CHECKOUT_PAGE }
            />
        )
    };
};

const addPaypalPaymentRenderer = (args, callback, instance) => {
    const {
        selectPaymentMethod,
        selectedPaymentCode
    } = instance.props;

    const [method] = args;
    const { code } = method;
    const isSelected = selectedPaymentCode === code;

    /*
     * disable PayPal PayLater as seperate payment method checkbox
     * both PayPal Express and PayPal PayLater will be handled by PayPal Smart Buttons
     * vvv
    */
    if (code === PAYPAL_PAYLATER_PAYMENT_METHOD_CODE) {
        return null;
    }

    if (code !== PAYPAL_EXPRESS_PAYMENT_METHOD_CODE) {
        return callback(...args);
    }

    return (
        <PaypalPayment
          key={ code }
          isSelected={ isSelected }
          method={ method }
          onClick={ selectPaymentMethod }
        />
    );
};

const addSetOrderButtonVisibilityToProps = (args, callback, instance) => {
    const {
        setOrderButtonVisibility
    } = instance.props;

    return {
        ...callback(...args),
        setOrderButtonVisibility
    };
};

export default {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: addPaypalPayments
        },
        'member-function': {
            renderPayment: addPaypalPaymentRenderer
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-function': {
            containerProps: addSetOrderButtonVisibilityToProps
        }
    }
};
