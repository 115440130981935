import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';

import './ProductBci.style';

/** @namespace Elganso/Component/ProductBci/Component */
export class ProductBciComponent extends PureComponent {
    renderContent() {
        const heading = 'Better Cotton Initiative';
        const id_block = 'bci';

        return (
            <ExpandableContent
              // show placeholder if the details are not loaded
              heading={ heading }
              mix={ { block: 'ProductBci', elem: 'Content' } }
            >
                <CmsBlock identifier={ id_block } />
            </ExpandableContent>
        );
    }

    render() {
        return (
            <ContentWrapper
              label="Better Cotton Initiative"
              mix={ { block: 'ProductBci' } }
              wrapperMix={ { block: 'ProductBci', elem: 'Wrapper' } }
            >
                { this.renderContent() }
            </ContentWrapper>
        );
    }
}

export default ProductBciComponent;
