/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import TagManager from 'react-gtm-module';

const initializeGogleTagManagerEvent = (args, callback, instance) => {
    const { googleConfigs } = instance.props;
    if (googleConfigs?.analitysID && googleConfigs?.isEnabled) {
        const gtm = window.google_tag_manager ? window.google_tag_manager[googleConfigs.analitysID] : null;
        const analitysIDCode = googleConfigs.analitysID.split("-")[1];
        if (!gtm) {
            let storeCode = 'es';
            if (window.localStorage?.config) {
                const localStorageConfig = JSON.parse(window.localStorage.config);
                storeCode = localStorageConfig.data.storeConfig.code;  
            }
    
            const tagManagerArgs = {
                gtmId: googleConfigs.analitysID,
                dataLayer: {
                    idioma: storeCode,
                    pais: storeCode.toUpperCase(),
                    ads_data_redaction: true
    
                },
                dataLayerName: 'YeboyeboDataLayer'
            }
            tagManagerArgs.dataLayer["developer_id"] = {};
            tagManagerArgs.dataLayer["developer_id"][analitysIDCode] = true
            TagManager.initialize(tagManagerArgs);
        } else  {
            //console.log("podemos llamar a pagina cambia router");
        }
    }
    return callback(...args);
};

// const mapStateToProps = (args, callback, instance) => {
//     const [state] = args;
//     return {
//         ...callback(...args),
//         googleConfigs: state.ConfigReducer?.googleConfigs
//     };
//  };

export default {
    // 'Component/ProductActions/Container/mapStateToProps': {
    //     function: mapStateToProps
    // },
    'Component/Router/Container': {
        'member-function': {
            render: initializeGogleTagManagerEvent
        }
    }
};
