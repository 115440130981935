/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Field } from 'Util/Query';

/**
  * WebFroms Query
  * @class WebForms
  * @namespace Elganso/Query/WebForms/Query */
export class WebFormsQuery {
    getWebFormQuery(form_id) {
        return new Field('webformsForm')
            .addFieldList(this._getWebformsFormfields())
            .addArgument('form_id', 'Int!', form_id);
    }

    _getWebformsFormfields() {
        return [
            'form_id',
            'name',
            'code',
            'redirect_url',
            'description',
            'success_text',
            'is_survey',
            'files_upload_limit',
            'images_upload_limit',
            'is_active',
            'submit_button_text',
            'is_url_parameters_accepted',
            'url_key',
            'meta_keywords',
            'meta_description',
            'meta_title',
            'below_text',
            this._getWebformsFieldset()
        ];
    }

    _getWebformsFieldset() {
        return new Field('fieldsets')
            .addFieldList(this._getWebformsFieldsetFields());
    }

    _getWebformsFieldsetFields() {
        return [
            'fieldset_id',
            'name',
            this._getWebformsFields()
        ];
    }

    _getWebformsFields() {
        return new Field('fields')
            .addFieldList(this._getWebformsFieldsFields());
    }

    _getWebformsFieldsFields() {
        return [
            'field_id',
            'form_id',
            'fieldset_id',
            'name',
            'type',
            'code',
            'result_label',
            'comment',
            'type_attributes_serialized',
            'is_required',
            'validation_required_message',
            'position',
            'is_active',
            'is_label_hidden',
            'custom_attributes',
            'width_proportion_lg',
            'width_proportion_md',
            'width_proportion_sm',
            'is_displayed_in_new_row_lg',
            'is_displayed_in_new_row_md',
            'is_displayed_in_new_row_sm',
            'css_container_class',
            'css_input_class',
            'css_input_style',
            'display_in_result',
            'browser_autocomplete',
            'is_unique',
            'unique_validation_message',
            'min_length',
            'min_length_validation_message',
            'max_length',
            'max_length_validation_message',
            'regex_validation_pattern',
            'regex_validation_message'
        ];
    }

    getWebformsSubmitForm(formId, formData) {
        return new Field('webformsSubmitForm')
            .addArgument('form_id', 'Int!', formId)
            .addArgument('formData', 'String!', formData)
            .addFieldList(this._getWebformsFormOutput());
    }

    _getWebformsFormOutput() {
        return [
            'success',
            'errors',
            'result'
        ];
    }
}

export default new WebFormsQuery();
