import {
    CATALOG_PRODUCT_LIST as SOURCE_CATALOG_PRODUCT_LIST,
    NEW_PRODUCTS as SOURCE_NEW_PRODUCTS,
    RECENTLY_VIEWED as SOURCE_RECENTLY_VIEWED,
    SLIDER as SOURCE_SLIDER
} from 'SourceComponent/WidgetFactory/WidgetFactory.config';

// TODO: implement SLIDER
export const SLIDER = SOURCE_SLIDER;

// TODO: implement NEW_PRODUCTS
export const NEW_PRODUCTS = SOURCE_NEW_PRODUCTS;

// TODO: implement CATALOG_PRODUCT_LIST
export const CATALOG_PRODUCT_LIST = SOURCE_CATALOG_PRODUCT_LIST;

// TODO: implement RECENTLY_VIEWED
export const RECENTLY_VIEWED = SOURCE_RECENTLY_VIEWED;

export const CATALOG_PRODUCT_SLIDER = 'CatalogProductSlider';
