// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
import TagManager from 'react-gtm-module';

const googleTagAddToCartEvent = (args, callback, instance) => {
    if (window.localStorage.config !== undefined) {
        const localStorageConfig = JSON.parse(window.localStorage.config);
        const gtmID = localStorageConfig.data.googleConfigs?.analitysID;
        const gtm = window.google_tag_manager ? window.google_tag_manager[gtmID] : null;

        if (gtm) {
            const { product: currentProduct } = instance.props;
            // eslint-disable-next-line fp/no-let
            let categories = '';

            if (currentProduct.categories) {
                categories = currentProduct.categories.map((cat) => cat.name).join(',');
            }

            const currencyCode = localStorageConfig.data.currency.base_currency_code;
            const tagManagerArgs = {
                gtmId: gtmID,
                dataLayer: {
                    event: 'add_to_cart',
                    ecommerce: {
                        // eslint-disable-next-line object-shorthand
                        items: [{
                            item_name: currentProduct.name,
                            item_id: currentProduct.sku,
                            price: currentProduct.price_range.minimum_price.final_price.value,
                            item_category: categories,
                            quantity: 1,
                            index: 1
                        }],
                        currency: currencyCode,
                        currencyCode,
                        value: currentProduct.price_range.minimum_price.final_price.value
                    }
                },
                dataLayerName: 'YeboyeboDataLayer'
            };

            TagManager.dataLayer(tagManagerArgs);
        }
    }

    return callback(...args);
};

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            addProductToCart: googleTagAddToCartEvent
        }
    }
};
