/**
 * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
 * @copyright Yeboyebo, Inc. All rights reserved.
 */
import ReactPixel from 'react-facebook-pixel';
// content_type: product
// value: 279.6
// contents: Hide
// [{"id":"4110s220010","quantity":2,"item_price":69.9},{"id":"4110s220010","quantity":2,"item_price":69.9}]
// num_items: 4
// currency: EUR
// order_id: 1020626227

function stringToHash(string) {
    let hash = 0;

    if (string.length === 0) {
        return hash;
    }

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash &= hash;
    }

    return String(hash);
}

function generateEventId(eName) {
    const uCookie = document.cookie;
    const uHash = stringToHash(uCookie);
    const url = window.location.href;
    const urlHash = stringToHash(url);

    function getTimeStamp() {
        if (!Date.now) {
            Date.now = () => {
                new Date().getTime();
            };
        }

        return Date.now();
    }

    const timestamp = String(getTimeStamp());

    return eName + uHash + urlHash + timestamp;
}

const facebookPixelCheckoutPurchase = (args, callback, instance) => {
    const localStorageConfig = JSON.parse(window.localStorage.config);
    const { fbq } = window;
    const { totales_pedido } = instance.state;

    if (fbq && totales_pedido !== undefined && totales_pedido !== '') {
        const { previousCartTotals, orderID } = instance.props;
        if (previousCartTotals.items) {
            const { prices } = previousCartTotals;
            const currencyCode = localStorageConfig.data.currency.base_currency_code;
            // eslint-disable-next-line max-len
            const products = previousCartTotals.items.map((item, _index) => ({
                id: item.product.sku,
                name: item.product.name,
                item_price: item.prices.price.value,
                qty: item.quantity,
                currency: currencyCode
            }));

            const pixelArgs = {
                currency: currencyCode,
                value: prices?.grand_total.value,
                content_type: 'product',
                contents: products,
                order_id: orderID
            };

            ReactPixel.fbq('track', 'Purchase', pixelArgs, { eventID: generateEventId('Purchase') });
        }
    }

    return callback(...args);
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            renderResumenPedido: facebookPixelCheckoutPurchase
        }
    }
};
