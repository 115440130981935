import { PureComponent } from 'react';

import ProductBci from './ProductBci.component';

/** @namespace Elganso/Component/ProductBci/Container */
export class ProductBciContainer extends PureComponent {
    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
    }

    render() {
        return (
            <ProductBci
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductBciContainer;
